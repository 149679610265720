import * as React from 'react';
import {Field, Form} from "react-final-form";
import {useDispatch, useSelector} from "react-redux";
import {useGetResumeHeaderQuery, useSaveResumeHeaderMutation, useUpdateUserMutation} from "../../../api/apiSlice";
import {stripHtml} from "string-strip-html";
import {validateEmail} from "../../../constants";
import {loginUser} from "../../../reducers/loginSlice";
import jwt_decode from "jwt-decode";

const ResumeHeader = () => {
    const {user, token} = useSelector(state => state.login);
    const [saveResumeHeader, saveResumeHeaderResult] = useSaveResumeHeaderMutation();
    const [updateUser, updateUserResult] = useUpdateUserMutation();
    const dispatch = useDispatch();
    const {
        data: resumeHeader,
        isFetching: isFetchingResumeHeader,
    } = useGetResumeHeaderQuery({
        "token": token,
        "user_id": user?.uuid
    }, {skip: false});

    const submit = values => {
        if ( typeof values?.email !== 'undefined' && values?.email?.trim() !== '' ) {
            values.email = stripHtml(values?.email)?.result;
            saveResumeHeader({
                "token": token,
                "method": values?.uuid !== '' ? "PUT": "POST",
                "body": {
                    "uuid": values?.uuid,
                    "first_name": values?.first_name,
                    "last_name": values?.last_name,
                    "city": values?.city,
                    "state": values?.state,
                    "zip_code": values?.zip_code,
                    "phone": values?.phone,
                    "email": values?.email
                }
            });
        }
    }

    const validate = values => {
        let errors =  {};
        if ( !validateEmail(values?.email) ) {
            errors.email = "Please enter a valid email address.";
        }
        return errors;
    }

    React.useEffect(() => {
        if (saveResumeHeaderResult?.status === 'fulfilled') {
            updateUser({
                "token": token,
                "body": {
                    "uuid": user?.uuid,
                    "email": user?.email ? user.email : '',
                    "first_name": user?.first_name ? user.first_name : '',
                    "last_name": user?.last_name ? user.last_name : '',
                    "resume_url": user?.resume_url ? user.resume_url : '',
                    "resume_in_draft_mode": typeof user?.resume_in_draft_mode !== "undefined" ? user.resume_in_draft_mode : true,
                    "progress": 'experience',
                    "tokenized": false,
                    "tokenize": true,
                    "linked_resources": [saveResumeHeaderResult?.data?.uuid]
                }
            });
        }
    }, [saveResumeHeaderResult?.data?.uuid, saveResumeHeaderResult?.status, token, updateUser, user.email, user.first_name, user.last_name, user.resume_in_draft_mode, user.resume_url, user?.uuid]);

    React.useEffect(() => {
        if (updateUserResult?.status === 'fulfilled') {
            dispatch(loginUser({
                "token": updateUserResult?.data?.token,
                "user": jwt_decode(updateUserResult?.data?.token)
            }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateUserResult?.status]);

    return(
        <>
            <Form
                onSubmit={submit}
                validate={validate}
                initialValues={{
                    uuid: resumeHeader && resumeHeader?.length > 0 && typeof resumeHeader[0]?.uuid !== 'undefined' ? resumeHeader[0]?.uuid : '',
                    first_name: resumeHeader && resumeHeader?.length > 0 && typeof resumeHeader[0]?.first_name !== 'undefined' ? resumeHeader[0]?.first_name : '',
                    last_name: resumeHeader && resumeHeader?.length > 0 && typeof resumeHeader[0]?.last_name !== 'undefined' ? resumeHeader[0]?.last_name : '',
                    city: resumeHeader && resumeHeader?.length > 0 && typeof resumeHeader[0]?.city !== 'undefined' ? resumeHeader[0]?.city : '',
                    state: resumeHeader && resumeHeader?.length > 0 && typeof resumeHeader[0]?.state !== 'undefined' ? resumeHeader[0]?.state : '',
                    zip_code: resumeHeader && resumeHeader?.length > 0 && typeof resumeHeader[0]?.zip_code !== 'undefined' ? resumeHeader[0]?.zip_code : '',
                    phone: resumeHeader && resumeHeader?.length > 0 && typeof resumeHeader[0]?.phone !== 'undefined' ? resumeHeader[0]?.phone : '',
                    email: resumeHeader && resumeHeader?.length > 0 && typeof resumeHeader[0]?.email !== 'undefined' ? resumeHeader[0]?.email : '',
                }}
                render={( {handleSubmit, form} ) => {
                    return(
                        <form onSubmit={handleSubmit}>
                            <>
                                { isFetchingResumeHeader || saveResumeHeaderResult?.isLoading || updateUserResult?.isLoading ? (
                                    <div className="p-2">
                                        <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                                        Working...
                                    </div>
                                ): null }
                                <Field
                                    name="first_name"
                                    render={({ input, meta }) => (
                                        <div className="mb-4 row">
                                            <label className="col-sm-2 col-form-label fw-bold">First Name</label>
                                            <div className="col-sm-10">
                                                <input
                                                    {...input}
                                                    autoComplete="off"
                                                    className="form-control"
                                                    placeholder="First name"
                                                    disabled={saveResumeHeaderResult?.isLoading || updateUserResult?.isLoading}
                                                />
                                            </div>
                                            {!saveResumeHeaderResult?.isLoading ? (
                                                meta.touched && meta.error && <p className="text-danger mt-2">{meta.error}</p>
                                            ) : null}
                                        </div>
                                    )}
                                />
                                <div className="mb-4">
                                    <Field
                                        name="last_name"
                                        render={({ input, meta }) => (
                                            <div className="mb-4 row">
                                                <label className="col-sm-2 col-form-label fw-bold">Last Name</label>
                                                <div className="col-sm-10">
                                                    <input
                                                        {...input}
                                                        autoComplete="off"
                                                        className="form-control"
                                                        placeholder="Last name"
                                                        disabled={saveResumeHeaderResult?.isLoading || updateUserResult?.isLoading}
                                                    />
                                                </div>
                                                {!saveResumeHeaderResult?.isLoading ? (
                                                    meta.touched && meta.error && <p className="text-danger mt-2">{meta.error}</p>
                                                ) : null}
                                            </div>
                                        )}
                                    />
                                </div>
                                <div className="mb-4">
                                    <Field
                                        name="city"
                                        render={({ input, meta }) => (
                                            <div className="mb-4 row">
                                                <label className="col-sm-2 col-form-label fw-bold">City</label>
                                                <div className="col-sm-10">
                                                    <input
                                                        {...input}
                                                        autoComplete="off"
                                                        className="form-control"
                                                        placeholder="City"
                                                        disabled={saveResumeHeaderResult?.isLoading || updateUserResult?.isLoading}
                                                    />
                                                </div>
                                                {!saveResumeHeaderResult?.isLoading ? (
                                                    meta.touched && meta.error && <p className="text-danger mt-2">{meta.error}</p>
                                                ) : null}
                                            </div>
                                        )}
                                    />
                                </div>
                                <div className="mb-4">
                                    <Field
                                        name="state"
                                        render={({ input, meta }) => (
                                            <div className="mb-4 row">
                                                <label className="col-sm-2 col-form-label fw-bold">State/Province</label>
                                                <div className="col-sm-10">
                                                    <input
                                                        {...input}
                                                        autoComplete="off"
                                                        className="form-control"
                                                        placeholder="State"
                                                        disabled={saveResumeHeaderResult?.isLoading || updateUserResult?.isLoading}
                                                    />
                                                </div>
                                                {!saveResumeHeaderResult?.isLoading ? (
                                                    meta.touched && meta.error && <p className="text-danger mt-2">{meta.error}</p>
                                                ) : null}
                                            </div>
                                        )}
                                    />
                                </div>
                                <div className="mb-4">
                                    <Field
                                        name="zip_code"
                                        render={({ input, meta }) => (
                                            <div className="mb-4 row">
                                                <label className="col-sm-2 col-form-label fw-bold">Zip Code</label>
                                                <div className="col-sm-10">
                                                    <input
                                                        {...input}
                                                        autoComplete="off"
                                                        className="form-control"
                                                        placeholder="Zip Code"
                                                        disabled={saveResumeHeaderResult?.isLoading || updateUserResult?.isLoading}
                                                    />
                                                </div>
                                                {!saveResumeHeaderResult?.isLoading ? (
                                                    meta.touched && meta.error && <p className="text-danger mt-2">{meta.error}</p>
                                                ) : null}
                                            </div>
                                        )}
                                    />
                                </div>
                                <div className="mb-4">
                                    <Field
                                        name="phone"
                                        render={({ input, meta }) => (
                                            <div className="mb-4 row">
                                                <label className="col-sm-2 col-form-label fw-bold">Phone</label>
                                                <div className="col-sm-10">
                                                    <input
                                                        {...input}
                                                        autoComplete="off"
                                                        className="form-control"
                                                        placeholder="Phone Number"
                                                        disabled={saveResumeHeaderResult?.isLoading || updateUserResult?.isLoading}
                                                    />
                                                </div>
                                                {!saveResumeHeaderResult?.isLoading ? (
                                                    meta.touched && meta.error && <p className="text-danger mt-2">{meta.error}</p>
                                                ) : null}
                                            </div>
                                        )}
                                    />
                                </div>
                                <div className="mt-4">
                                    <Field
                                        name="email"
                                        render={({ input, meta }) => (
                                            <div className="mb-4 row">
                                                <label className="col-sm-2 col-form-label fw-bold">Email Address</label>
                                                <div className="col-sm-10">
                                                    <input
                                                        {...input}
                                                        autoComplete="off"
                                                        className="form-control"
                                                        placeholder="Email Address"
                                                        disabled={saveResumeHeaderResult?.isLoading || updateUserResult?.isLoading}
                                                    />
                                                </div>
                                                {!saveResumeHeaderResult?.isLoading ? (
                                                    meta.touched && meta.error && <p className="text-danger mt-2">{meta.error}</p>
                                                ) : null}
                                            </div>
                                        )}
                                    />
                                </div>
                                <div className="float-end">
                                    <button
                                        className="btn btn-primary mt-4"
                                        disabled={saveResumeHeaderResult?.isLoading || updateUserResult?.isLoading}
                                        onClick={() => {
                                            submit();
                                        }}
                                    >
                                        {
                                            saveResumeHeaderResult?.isLoading || updateUserResult?.isLoading ? (
                                                <div className="p-2">
                                                    <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                                                    Saving...
                                                </div>
                                            ): "Continue to Experience"
                                        }
                                    </button>
                                </div>
                            </>
                        </form>
                    )
                }}
            />
        </>
    );
}

export default ResumeHeader;
