import * as React from 'react';
import {removeFromCart} from "../../reducers/resumeSlice";
import * as Icon from "react-bootstrap-icons";
import {useDispatch} from "react-redux";

const RemoveFromCart = ({job}) => {
    const dispatch = useDispatch();
    return(
        <button
            className="btn btn-danger btn-sm float-end"
            onClick={() => {dispatch(removeFromCart( {"job": job}))}}
        >
            <Icon.CartDash /> Remove from cart
        </button>
    )
}

export default RemoveFromCart;
