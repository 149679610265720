import * as React from 'react';

const Footer = () => {
    return (
        <div className="container">
            <footer className="d-flex flex-wrap justify-content-between align-items-center py-3 my-4"></footer>
        </div>
    );
}

export default Footer;
