import * as React from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {
    useGetJobByIDQuery, useGetResumeEducationQuery,
    useGetResumeExperienceQuery,
    useGetResumeHeaderQuery,
    useGetResumeSkillsQuery,
    useGetResumeSummaryQuery,
} from "../../api/apiSlice";
import {useSelector} from "react-redux";
import DisplayContact from "./Contact/DisplayContact";
import DisplaySummary from "./Summary/DisplaySummary";
import DisplayExperience from "./Experiences/DisplayExperience";
import DisplaySill from "./Skills/DisplaySkill";
import DisplayEducation from "./Education/DisplayEducation";
import ExperienceForm from "./Experiences/ExperienceForm";
import SkillForm from "./Skills/SkillForm";
import EducationForm from "./Education/EducationForm";
import SummaryForm from "./Summary/SummaryForm";
import DisplayCoverLetter from "./Job/DisplayCoverLetter";
import CoverLetterForm from "./Job/CoverLetterForm";
import * as Icon from "react-bootstrap-icons";

const Jobs = () => {
    const {token, user} = useSelector(state => state.login);
    const {activeExperienceID, activeSkillID, activeSchoolID, activeSummaryID, showCoverLetterForm} = useSelector(state => state.resume);
    const {uuid} = useParams();
    const navigate = useNavigate();

    const {
        data: job,
        isFetching,
        refetch: reFetchJob,
        isSuccess: isSuccessfulJobFetch,
    } = useGetJobByIDQuery({
        "token": token,
        "uuid": uuid
    }, {skip: false});

    // Build resume from contact, summary, skills, experiences, and education
    // Get contact
    const { data: contact,
        isSuccess: isSuccessfulContactFetch,
    } = useGetResumeHeaderQuery({"token": token, "user_id": user?.uuid}, {skip: isSuccessfulJobFetch === false});

    // Get summary
    const { data: summary,  refetch: reFetchSummary,
        isSuccess: isSuccessfulSummeryFetch,
    } = useGetResumeSummaryQuery({"token": token, "user_id": user?.uuid}, {skip: isSuccessfulContactFetch === false});

    // Get skills
    const { data: skills,  refetch: reFetchSkills, isSuccess: isSuccessfulSkillsFetch,
    } = useGetResumeSkillsQuery({"token": token, "user_id": user?.uuid}, {skip: isSuccessfulSummeryFetch === false});

    // Get experiences
    const { data: experiences,  refetch: reFetchExperiences,
        isSuccess: isSuccessfulExperiencesFetch
    } = useGetResumeExperienceQuery({"token": token, "user_id": user?.uuid}, {skip: isSuccessfulSkillsFetch === false});

    // Get education
    const { data: education,  refetch: reFetchEducation,
    } = useGetResumeEducationQuery({"token": token, "user_id": user?.uuid}, {skip: isSuccessfulExperiencesFetch === false});

    return(
        <>
            <div className="container bg-white">
                <div className="container col-xxl-8 px-4 py-5">
                    {isFetching && <p>Working...</p>}
                    <ul className="nav nav-pills mb-3 justify-content-end" id="pills-tab" role="tablist">
                        <li className="nav-item" role="presentation">
                            <button className="nav-link active" id="pills-job-tab" data-bs-toggle="pill"
                                    data-bs-target="#pills-job" type="button" role="tab" aria-controls="pills-job"
                                    aria-selected="true">Job Description
                            </button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link" id="pills-letter-tab" data-bs-toggle="pill"
                                    data-bs-target="#pills-letter" type="button" role="tab"
                                    aria-controls="pills-letter" aria-selected="false">Cover Letter
                            </button>
                        </li>
                        <li className="nav-item" role="presentation">
                            <button className="nav-link" id="pills-resume-tab" data-bs-toggle="pill"
                                    data-bs-target="#pills-resume" type="button" role="tab"
                                    aria-controls="pills-resume" aria-selected="false">Resume
                            </button>
                        </li>
                    </ul>
                    <div className="tab-content" id="pills-tabContent">
                        <div className="tab-pane fade show active" id="pills-job" role="tabpanel"
                             aria-labelledby="pills-job-tab" tabIndex="0">
                            <h4 className="border-bottom p-2">{job?.title}</h4>
                            <div className="card border-0">
                                <div className="card-body" style={{height:'65vh', overflow:"auto"}}>
                                    <p><a target="_blank" rel="noreferrer" href={job?.url}>Website</a></p>
                                    <div className="mb-4" dangerouslySetInnerHTML={{__html: job?.description}}></div>
                                </div>
                                <div className="card-footer bg-white">
                                    <button
                                        className="btn btn-light float-end me-2"
                                        disabled={isFetching}
                                        onClick={() => {
                                            navigate('/jobs/' + job?.uuid + '/edit');
                                        }}><Icon.PencilSquare /></button>
                                </div>
                            </div>
                        </div>
                        <div className="tab-pane fade" id="pills-letter" role="tabpanel"
                             aria-labelledby="pills-letter-tab" tabIndex="0">
                            <h4 className="border-bottom p-2">Job specific cover letter</h4>
                            {
                                showCoverLetterForm ? (
                                    <CoverLetterForm
                                        job={job}
                                        reFetchJob={reFetchJob}

                                    />
                                ): (
                                    <DisplayCoverLetter
                                        job={job}
                                        reFetchJob={reFetchJob}
                                        summary={summary?.[0]}
                                        lastExperience={experiences?.length > 0 ? experiences?.[experiences?.length - 1] : null}
                                        borderless={true}
                                        editable={true}
                                        allowRegeneration={true}
                                    />
                                )
                            }
                        </div>
                        <div className="tab-pane fade" id="pills-resume" role="tabpanel"
                             aria-labelledby="pills-resume-tab" tabIndex="0">
                            <h4 className="border-bottom p-2">Your latest resume</h4>
                           <div className="card border-0">
                               <div className="card-body" style={{height:'65vh', overflow:"auto"}}>
                                   {
                                       contact?.length > 0 ? (
                                           contact?.map((contact, index) => {
                                               return(
                                                   <DisplayContact contact={contact} key={index} />
                                               );
                                           })
                                       ): null
                                   }

                                   {
                                       summary?.length > 0 ? (
                                           summary?.map((summary, index) => {
                                               return(
                                                   typeof activeSummaryID !== 'undefined' && activeSummaryID === summary?.uuid ? (
                                                       <SummaryForm key={index} summary={summary} reFetchSummary={reFetchSummary} />
                                                   ): <DisplaySummary
                                                       summary={summary}
                                                       reFetchSummary={reFetchSummary}
                                                       key={index}
                                                       borderless={true}
                                                       editable={true}
                                                       allowRegeneration={true}
                                                       job={job}
                                                   />
                                               );
                                           })
                                       ): null
                                   }

                                   {
                                       experiences?.length > 0 ? (
                                           experiences?.map((experience, index) => {
                                               return(
                                                   typeof activeExperienceID !== 'undefined' && activeExperienceID === experience?.uuid ? (
                                                       <ExperienceForm
                                                           key={index}
                                                           num_experiences={experiences?.length}
                                                           experience={experience}
                                                           reFetchExperiences={reFetchExperiences}
                                                       />
                                                   ): <DisplayExperience
                                                       experience={experience}
                                                       reFetchExperiences={reFetchExperiences}
                                                       key={index}
                                                       borderless={true}
                                                       editable={true}
                                                       allowRegeneration={true}
                                                       job={job}
                                                   />
                                               );
                                           })
                                       ): null
                                   }

                                   {
                                       skills?.length > 0 ? (
                                           skills?.map((skill, index) => {
                                               return(
                                                   typeof activeSkillID !== 'undefined' && activeSkillID === skill?.uuid ? (
                                                       <SkillForm key={index} skill={skill} reFetchSkills={reFetchSkills} />
                                                   ): <DisplaySill
                                                       skill={skill}
                                                       reFetchSkills={reFetchSkills}
                                                       key={index}
                                                       borderless={true}
                                                       editable={true}
                                                       allowRegeneration={true}
                                                       job={job}
                                                   />
                                               );
                                           })
                                       ): null
                                   }

                                   {
                                       education?.length > 0
                                       &&
                                       <div className="card border-0">
                                           <div className="card-header bg-white">Education</div>
                                       </div>
                                   }

                                   {
                                       education?.length > 0 ? (
                                           education?.map((school, index) => {
                                               return(
                                                   typeof activeSchoolID !== 'undefined' && activeSchoolID === school?.uuid ? (
                                                       <EducationForm key={index} school={school} num_schools={education?.length} reFetchEducation={reFetchEducation} />
                                                   ): <DisplayEducation
                                                       school={school}
                                                       key={index}
                                                       borderless={true}
                                                       editable={true}
                                                       allowRegeneration={true}
                                                   />
                                               );
                                           })
                                       ): null
                                   }
                               </div>
                           </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Jobs;
