import * as React from 'react';
import {useGetResumeExperienceQuery} from "../../../api/apiSlice";
import {useDispatch, useSelector} from "react-redux";
import ExperienceForm from "./ExperienceForm";
import DisplayExperience from "./DisplayExperience";
import {toggleNewExperienceForm} from "../../../reducers/resumeSlice";
import moment from "moment";

const Experiences = ({updateUser, updateUserResult}) => {
    const {user, token} = useSelector(state => state.login);
    const {activeExperienceID, showExperienceForm} = useSelector(state => state.resume);
    const dispatch = useDispatch();
    const {
        data: resumeExperiences,
        isFetching: isFetchingResumeExperience,
        refetch: reFetchExperiences
    } = useGetResumeExperienceQuery({
        "token": token,
        "user_id": user?.uuid
    }, {skip: false});

    const compare = (a, b) => {
        return new Date(moment(b.start_date).toDate()) - new Date(moment(a.start_date).toDate());
    }

    const displayExperienceForms = (experiences) => {
        let sortedExperiences = [];
        if (experiences?.length > 0) {
            let arrayForSort = [...experiences];
            sortedExperiences = arrayForSort?.sort(compare);
        }
        return sortedExperiences?.length > 0 ?
            sortedExperiences.map((experience, index) => {
                return(
                    typeof activeExperienceID !== 'undefined' && activeExperienceID === experience?.uuid ? (
                        <ExperienceForm
                            key={index}
                            num_experiences={experiences?.length}
                            updateUserResult={updateUserResult}
                            experience={experience}
                            reFetchExperiences={reFetchExperiences}
                        />
                    ): <DisplayExperience experience={experience} key={index} />
                );
            })
        : <ExperienceForm num_experiences={0} updateUserResult={updateUserResult} reFetchExperiences={reFetchExperiences} />;
    }
    const experiencesContainer = (experiences) => {
        return(
            <div className="container mt-6">
                {displayExperienceForms(experiences)}
                {experiences?.length > 0 && !showExperienceForm ? (
                    <div className="float-end mt-4">
                        <button
                            className="btn btn-light me-2"
                            onClick={() => {
                                dispatch(toggleNewExperienceForm({
                                    "showExperienceForm": true,
                                }));
                            }}>Add new experience
                        </button>
                        <button
                            className="btn btn-primary"
                            onClick={() => {
                                updateUser({
                                    "token": token,
                                    "body": {
                                        "uuid": user?.uuid,
                                        "email": user?.email ? user.email : '',
                                        "first_name": user?.first_name ? user.first_name : '',
                                        "last_name": user?.last_name ? user.last_name : '',
                                        "resume_url": user?.resume_url ? user.resume_url : '',
                                        "resume_in_draft_mode": typeof user?.resume_in_draft_mode !== "undefined" ? user.resume_in_draft_mode : true,
                                        "progress": 'skills',
                                        "tokenized": false,
                                        "tokenize": true,
                                        "linked_resources": experiences?.map((experience) => {return experience?.uuid}),
                                    }
                                });
                            }}>
                            {
                                updateUserResult?.isLoading ? (
                                    <div className="p-2">
                                        <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                                        Continue to Skills
                                    </div>
                                ): 'Continue to Skills'
                            }
                        </button>
                    </div>
                ): null}
                {showExperienceForm && <ExperienceForm num_experiences={experiences?.length} reFetchExperiences={reFetchExperiences} updateUserResult={updateUserResult} />}
            </div>
        )
    };
    return(
        <>
            {
                isFetchingResumeExperience ? (
                    <div className="p-2">
                        <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                        Working...
                    </div>
                ) : experiencesContainer(resumeExperiences)
            }
        </>
    );
}

export default Experiences;
