import * as React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {logoutUser} from "../../reducers/loginSlice";
import {useGetJobsQuery} from "../../api/apiSlice";
import {useNavigate} from "react-router-dom";

const Sidebar = () => {
    const {user, token} = useSelector(state => state.login);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {
        data: jobs,
        isFetching,
    } = useGetJobsQuery({
        "token": token,
        "user_id": user?.uuid
    });

    return(
        <>
            <div className="d-flex flex-column bg-light border-right flex-shrink-0 p-3" style={{width: '280px'}}>
                <a href="/" className="d-flex align-items-center mb-3 mb-md-0 me-md-auto text-black text-decoration-none">
                    <svg className="bi me-2" width="40" height="32">
                    </svg>
                    <span className="fs-4 mb-4">Hirepath</span>
                </a>
                <button className="btn btn-dark mb-4" onClick={() => {navigate('/')}}>New Job</button>
                {isFetching ? <p>Loading jobs...</p> : null}
                <div style={{height:'95vh', overflow:'auto'}}>
                    <nav className="nav flex-column">
                        {jobs?.map((job, index) => {
                            return(
                                <button type="button" className="nav-link active text-start" key={index} onClick={() => {navigate('/jobs/' + job?.uuid)}}>{job?.title?.substring(0,250)}</button>
                            )
                        })}
                    </nav>
                </div>
                <hr />
                    <div className="dropdown">
                        <span
                           className="d-flex align-items-center text-dark text-decoration-none dropdown-toggle"
                           id="dropdownUser1" data-bs-toggle="dropdown" aria-expanded="false">
                            <strong>{user.first_name}</strong>
                        </span>
                        <ul className="dropdown-menu dropdown-menu-light text-small shadow"
                            aria-labelledby="dropdownUser1">
                            <li><button className="btn btn-light dropdown-item" onClick={() => { dispatch(logoutUser()); navigate('/')}}>Sign out</button></li>
                        </ul>
                    </div>
            </div>
        </>
    )
};

export default Sidebar;
