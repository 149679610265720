import * as React from "react";
import moment from "moment";
import * as Icon from "react-bootstrap-icons";
import {useDispatch, useSelector} from "react-redux";
import {showExperienceForm} from "../../../reducers/resumeSlice";
import {useGenerateMutation, useGetTokenizedResourceByIDQuery} from "../../../api/apiSlice";
import {stripHtml} from "string-strip-html";

const DisplayExperience = ({experience, reFetchExperiences, borderless=false, editable=true, allowRegeneration=false, job = undefined}) => {
    const {token} = useSelector(state => state.login);
    const dispatch = useDispatch();
    const className = borderless ? "card border-0" : "card mt-4";
    const headerClassName = borderless ? "card-header bg-white" : "card-header";
    const footerClassName = borderless ? "card-footer bg-white" : "card-footer";
    const [generate, generateResult] = useGenerateMutation();
    const { data: tokenizedExperience,
    } = useGetTokenizedResourceByIDQuery({
        "token": token,
        "uuid": experience?.uuid,
        "compare_resource_id": typeof job?.uuid !== 'undefined' ? job?.uuid : undefined,
    }, {skip: false});
    const description = typeof job?.description !== 'undefined' ? stripHtml(job?.description)?.result : '';
    const descriptionArr = description?.split('.');
    const parsedDescription = descriptionArr?.[0] + ' ' + descriptionArr?.[1] + ' ' + descriptionArr?.[2];
    const intervalRef = React.useRef(null);
    const [isGenerating, setIsGenerating] = React.useState(false);

    React.useEffect(() => {
        if (generateResult?.status === 'fulfilled') {
            // Set up polling every 30 seconds
            intervalRef.current = setInterval(reFetchExperiences, 5000);
        }
    }, [generateResult?.status, reFetchExperiences]);

    React.useEffect(() => {
        if (generateResult?.status === 'pending') {
            setIsGenerating(true);
        }
    }, [generateResult?.status]);

    React.useEffect(() => {
        if (experience?.job_description !== '_llm_processing') {
            setIsGenerating(false);
            clearInterval(intervalRef.current);
        }
    }, [experience?.job_description]);

    return(
        <div className={className}>
            <div className={headerClassName}>
                {experience?.employer}
            </div>
            <div className="card-body">
                <div className="card-title">
                    {experience?.title}
                </div>
                <div className="card-subtitle mb-2 text-muted">
                    {experience?.location} <span className="float-end">{moment(experience?.start_date).format('MM/YYYY')} {experience?.end_date !== '' ? ' - ' + moment(experience?.end_date).format('MM/YYYY'): null}</span>
                </div>
                {
                    isGenerating || experience?.job_description === '_llm_processing' ? (
                        <button className="btn btn-light bg-white" type="button" disabled>
                            <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                            <span className="ms-2 me-2"><Icon.Robot /></span>Working! It might take a few minutes...
                        </button>
                    ): (
                        <div className="card-text" dangerouslySetInnerHTML={{__html: experience?.job_description}}></div>
                    )
                }
            </div>
            <div className={footerClassName}>
                {
                    allowRegeneration
                    &&
                    <button
                        className="btn btn-light float-end"
                        disabled={isGenerating}
                        onClick={() => {
                            let data = {
                                "field": "job_description",
                                "resource_id": experience?.uuid,
                            };
                            let prompts = [];
                            tokenizedExperience?.sentences?.forEach((sentence) => {
                                let context = '';
                                for (let i = 0; i < sentence?.similarities?.length; i++) {
                                    if (typeof sentence?.similarities?.[i]?.sentence !== 'undefined' && sentence?.similarities?.[i]?.sentence !== '') {
                                        context += sentence?.similarities?.[i]?.sentence + ' ';
                                    }
                                }
                                if (context === '') {
                                    context = parsedDescription.trim();
                                }
                                prompts.push({
                                    "assistant_content": sentence.text,
                                    "user_content": "Given the following context about a job: " + context +
                                        " Rewrite this experience section of the job seeker's resume: " + sentence?.text + ". Don't use 'I'. Be very concise. Avoid verbose language. Personalized it to increase the candidate's chances of getting hired. Please format your response using html paragraphs tags to indicate paragraphs. ",
                                    "model": "gpt-4"
                                });
                            });
                            if (prompts.length === 0) {
                                data["prompts"] = [{
                                    "assistant_content": parsedDescription,
                                    "user_content": "Given the following context about a job: " + parsedDescription +
                                        " Rewrite this experience section of the job seeker's resume: " + experience?.job_description + ". Don't use 'I'. Be very concise. Avoid verbose language. Personalized it to increase the candidate's chances of getting hired. Please format your response using html paragraphs tags to indicate paragraphs. ",
                                    "model": "gpt-4"
                                }];
                            } else {
                                data["prompts"] = prompts;
                            }
                            generate({
                                "token": token,
                                "body": data
                            });
                        }}><Icon.ArrowRepeat /> <Icon.Robot /></button>
                }
                {
                    editable
                    &&
                    <button
                        className="btn btn-light float-end me-2"
                        disabled={isGenerating}
                        onClick={() => {
                            dispatch(showExperienceForm({
                                "activeExperienceID": experience?.uuid,
                            }))
                        }}><Icon.PencilSquare/>
                    </button>
                }
            </div>
        </div>
    );
}

export default DisplayExperience;
