import * as React from "react";
import * as Icon from 'react-bootstrap-icons';
import {io} from "socket.io-client";
import {generatePassword, OAUTH_URL} from "../../constants";
import jwt_decode from 'jwt-decode';
import {useDispatch, useSelector} from 'react-redux';
import {loginUser} from "../../reducers/loginSlice";
import { useNavigate } from "react-router-dom";
import {useIsValidUserMutation, useSignUpMutation} from "../../api/apiSlice";
const socket = io(OAUTH_URL, { transports: ['websocket', 'polling', 'flashsocket'] });

const Auth = () => {
    const {isAuthenticated} = useSelector(state => state.login);
    const [signedIn, setSignedIn] = React.useState(false);
    const [authUser, setAuthUser] = React.useState({});
    const [popup, setPopup] = React.useState({});
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const urlParams = new URLSearchParams(window.location.search);
    const cta = urlParams.get('cta')
    const [signUp, signUpResult] = useSignUpMutation();
    const [validateUser, validateUserResult] = useIsValidUserMutation();

    React.useEffect(() => {
        socket.on('google', token => {
            const user = jwt_decode(token);
            setAuthUser(user);
            // check if user exists
            validateUser({
                "email": user?.email
            });
        });

        return () => {
            socket.off('google');
        };
    }, [cta, signUp, validateUser]);

    // if user exists, login, else register user
    React.useEffect(() => {
        let userExists = true;
        if (validateUserResult?.error?.data?.errors?.length > 0) {
            validateUserResult?.error?.data?.errors?.forEach((error) => {
                if (error === "The resource you provided does not exist.") {
                    userExists = false;
                }
            });

            if (!userExists) {
                // if user does not exist, register
                signUp({
                    "first_name": authUser?.first_name,
                    "last_name": authUser?.last_name,
                    "email": authUser?.email,
                    "password": generatePassword()
                });
            }
        } else if (validateUserResult?.status === 'fulfilled' && validateUserResult?.data?.token) {
            dispatch(loginUser({token: validateUserResult?.data?.token, user: jwt_decode(validateUserResult?.data?.token)}));
            setSignedIn(true);
        }
    }, [validateUserResult, dispatch, signUp, authUser]);

    React.useEffect(() => {
        if (signUpResult?.status === 'fulfilled' && signUpResult?.data?.token) {
            dispatch(loginUser({token: signUpResult?.data?.token, user: jwt_decode(signUpResult?.data?.token)}));
            setSignedIn(true);
        }
    }, [dispatch, signUpResult])

    React.useEffect(() => {
        if ((signedIn || validateUserResult?.isError === true || signUpResult?.isError === true) && typeof popup.close === "function" ) {
            popup.close();
        }
        const timer = setInterval(() => {
            if ( typeof popup.closed !== "undefined" && popup.closed === true && isAuthenticated ) {
                clearInterval(timer);
                navigate("/");
            }
        }, 500);

        if (isAuthenticated && typeof popup.close !== "function") navigate("/");
    }, [signedIn, popup, isAuthenticated, signUpResult, validateUserResult, navigate]);

    const openPopup = () => {
        const width = 600, height = 600;
        const left = (window.innerWidth / 2) - (width / 2);
        const top = (window.innerHeight / 2) - (height / 2);
        const url = `${OAUTH_URL}/google?socketId=${socket.id}`;

        return window.open(url, '',
            `toolbar=no, location=no, directories=no, status=no, menubar=no, 
                  scrollbars=no, resizable=no, copyhistory=no, width=${width}, 
                  height=${height}, top=${top}, left=${left}`
        );
    }

    const startAuth = (e) => {
        validateUserResult?.reset();
        signUpResult?.reset();
        e.preventDefault();
        const popup = openPopup();
        setSignedIn(false);
        setPopup(popup);
    }

    return(
        <>
            <main>
                <div className="position-relative overflow-hidden p-3 p-md-5 m-md-3 text-center bg-body-tertiary">
                    <div className="col-md-6 mx-auto my-5">
                        <h1 className="display-4 fw-bold">Get hired</h1>
                        <h3 className="fw-normal text-muted mb-3">Rise to the top of the applicant pool. Let our AI craft highly personalized resumes and cover letters that warrant a callback.</h3>
                        <div className="d-flex gap-3 justify-content-center lead fw-normal">
                            <button className="btn btn-lg btn-outline-primary" onClick={startAuth} disabled={signUpResult?.isLoading}>
                                <Icon.Google />
                                <span className="p-2">Try it free, and get hired</span>
                            </button>
                        </div>
                    </div>
                    <div className="product-device shadow-sm d-none d-md-block"></div>
                    <div className="product-device product-device-2 shadow-sm d-none d-md-block"></div>
                </div>

                <div className="container px-4 py-5" id="hanging-icons">
                    <h2 className="pb-2 border-bottom">Why Hirepath?</h2>
                    <div className="row g-4 py-5 row-cols-1 row-cols-lg-3">
                        <div className="col d-flex align-items-start">
                            <div>
                                <h3 className="fs-2 text-body-emphasis">
                                    Free
                                </h3>
                                <p>
                                   Hirepath is free. All you need is a Gmail account to get started.
                                </p>

                            </div>
                        </div>
                        <div className="col d-flex align-items-start">
                            <div>
                                <h3 className="fs-2 text-body-emphasis">Done in minutes</h3>
                                <p>
                                    No need to fret about the time it takes to update your resume. Get a personalized cover letter and resume for each job in minutes.
                                </p>
                            </div>
                        </div>
                        <div className="col d-flex align-items-start">
                            <div>
                                <h3 className="fs-2 text-body-emphasis">Effective</h3>
                                <p>
                                    Ensure your application resonates with both AI recruitment tools and human recruiters.
                                </p>

                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    );
};

export default Auth;
