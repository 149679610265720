import * as React from 'react';
import {useGetResumeEducationQuery} from "../../../api/apiSlice";
import {useDispatch, useSelector} from "react-redux";
import EducationForm from "./EducationForm";
import DisplayEducation from "./DisplayEducation";
import {toggleNewSchoolForm} from "../../../reducers/resumeSlice";
import moment from "moment";

const Education = ({updateUser, updateUserResult}) => {
    const {user, token} = useSelector(state => state.login);
    const {activeSchoolID, showSchoolForm} = useSelector(state => state.resume);
    const dispatch = useDispatch();
    const {
        data: schools,
        isFetching: isFetchingResumeEducation,
        refetch: reFetchEducation
    } = useGetResumeEducationQuery({
        "token": token,
        "user_id": user?.uuid
    }, {skip: false});

    const compare = (a, b) => {
        return new Date(moment(b.graduation_date).toDate()) - new Date(moment(a.graduation_date).toDate());
    }

    const displayEducationForms = (schools) => {
        let sortedSchools = [];
        if (schools?.length > 0) {
            let arrayForSort = [...schools];
            sortedSchools = arrayForSort?.sort(compare);
        }
        return sortedSchools?.length > 0 ?
            sortedSchools.map((school, index) => {
                return(
                    typeof activeSchoolID !== 'undefined' && activeSchoolID === school?.uuid ? (
                        <EducationForm key={index} school={school} reFetchEducation={reFetchEducation} updateUserResult={updateUserResult} />
                    ): <DisplayEducation school={school} key={index} />
                );
            })
            : <EducationForm reFetchEducation={reFetchEducation} updateUserResult={updateUserResult} />;
    }
    const EducationContainer = (schools) => {
        return(
            <div className="container mt-6">
                {displayEducationForms(schools)}
                {schools?.length > 0 && !showSchoolForm ? (
                    <div className="float-end mt-4">
                        <button
                            className="btn btn-light me-2"
                            onClick={() => {
                                dispatch(toggleNewSchoolForm({
                                    "showSchoolForm": true,
                                }));
                            }}>Add new school
                        </button>
                        <button
                            className="btn btn-primary"
                            onClick={() => {
                                updateUser({
                                    "token": token,
                                    "body": {
                                        "uuid": user?.uuid,
                                        "email": user?.email ? user.email : '',
                                        "first_name": user?.first_name ? user.first_name : '',
                                        "last_name": user?.last_name ? user.last_name : '',
                                        "resume_url": user?.resume_url ? user.resume_url : '',
                                        "resume_in_draft_mode": typeof user?.resume_in_draft_mode !== "undefined" ? user.resume_in_draft_mode : true,
                                        "progress": 'summary',
                                        "tokenized": false,
                                        "tokenize": true,
                                        "linked_resources": schools?.map((experience) => {return experience?.uuid}),
                                    }
                                });
                            }}>
                            {
                                updateUserResult?.isLoading ? (
                                    <div className="p-2">
                                        <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                                        Continue to Summary
                                    </div>
                                ): 'Continue to Summary'
                            }
                        </button>
                    </div>
                ): null}
                {showSchoolForm && <EducationForm num_schools={schools?.length} reFetchEducation={reFetchEducation} updateUserResult={updateUserResult} />}
            </div>
        )
    };
    return(
        <>
            {
                isFetchingResumeEducation ? (
                    <div className="p-2">
                        <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                        Working...
                    </div>
                ) : EducationContainer(schools)
            }
        </>
    );
}

export default Education;
