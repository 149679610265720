import * as React from 'react';
import {useSelector} from "react-redux";
import CheckoutButton from "./CheckoutButton";
import CartItems from "./CartItems";

const Cart = () => {
    const {showCart, cart} = useSelector(state => state.resume);
    let className = 'bg-light offcanvas offcanvas-start' + (showCart ? ' show' : '') ;
    return(
        <>
            <div className={className} data-bs-scroll="true" tabIndex="-1" id="offcanvasWithBothOptions"
                 aria-labelledby="offcanvasWithBothOptionsLabel">
                <div className="offcanvas-header">
                    <h5 className="offcanvas-title" id="offcanvasWithBothOptionsLabel">Your Jobs Cart</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                </div>
                <div className="offcanvas-body">
                    <CheckoutButton cart={cart} />
                   <CartItems cart={cart} />
                </div>
            </div>
        </>
    )
}

export default Cart;
