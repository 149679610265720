import * as React from "react";

const DisplayContact = ({contact}) => {
    return(
        <div className="card border-0 mb-3 text-center">
            <div className="card-body border-0">
                <h5 className="card-title">{contact?.first_name} {contact?.last_name}</h5>
                <h6 className="card-subtitle mb-2 text-muted">{contact?.title}</h6>
                <p className="card-text m-0">{contact?.email}</p>
                <p className="card-text m-0">{contact?.phone}</p>
                <p className="card-text m-0">{contact?.city}, {contact?.state} {contact?.zip_code}</p>
            </div>
        </div>
    )
}
export default DisplayContact;
