import * as React from "react";

const ResumePageTitle = ({progress}) => {
    const switcher = (progress) => {
        switch(progress) {
            case 'experience':
                // experience block
                return (
                    <h6 className="mt-4 mb-4 p-2 text-muted">
                        Add your work history
                    </h6>
                );
            case 'skills':
                // skills block
                return (
                    <h6 className="mt-4 mb-4 p-2 text-muted">
                        Highlight your skills
                    </h6>
                );
            case 'education':
                // education block
                return (
                    <h6 className="mt-4 mb-4 p-2 text-muted">
                        List your education
                    </h6>
                );
            case 'summary':
                // summary block
                return (
                    <h6 className="mt-4 mb-4 p-2 text-muted">
                        Add an executive summary to your resume
                    </h6>
                );
            default:
                // heading block
                return (
                    <h6 className="mt-4 mb-4 p-2 text-muted">
                        Start by telling employers how to contact you
                    </h6>
                );
        }
    }

    return switcher(progress);
}

export default ResumePageTitle;
