import * as React from 'react';
import RemoveFromCart from "./RemoveFromCart";

const CartItems = () => {
    const cart = JSON.parse(localStorage.getItem('cart')) !== null ? JSON.parse(localStorage.getItem('cart')) : {};
    return(
        Object.keys(cart).length > 0 ? Object.keys(cart).map((key, index) => {
            return(
                <div className="card border-0 border-top bg-light" key={index}>
                    <div className="card-header border-0 bg-light"><h5>{cart[key]?.title}</h5></div>
                    <div className="card-body" dangerouslySetInnerHTML={{__html: cart[key]?.description}}></div>
                    <div className="card-footer border-0 bg-white"><RemoveFromCart job={cart[key]} /></div>
                </div>
            );
        }): null
    )
};

export default CartItems;
