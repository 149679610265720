import {configureStore} from '@reduxjs/toolkit';
import resumeReducer from './reducers/resumeSlice';
import loginReducer from './reducers/loginSlice';
import {apiSlice} from './api/apiSlice';

export const store = configureStore({
  reducer: {
      login: loginReducer,
      resume: resumeReducer,
    [apiSlice.reducerPath]: apiSlice.reducer,
  },
  middleware: getDefaultMiddleware =>
      getDefaultMiddleware({
        thunk: true,
        immutableCheck: true,
        serializableCheck: false,
      }).concat(apiSlice.middleware),
});
