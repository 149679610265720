import * as React from 'react';
import {useSelector} from 'react-redux';
import Auth from "./Auth";
import Resume from "./Resume";
import Sidebar from "./Sidebar";
import Dashboard from "../Dashboard";

const Home = () => {
    const {isAuthenticated, user} = useSelector(state => state.login);
    return (
        <>
            <div className="d-flex flex-nowrap" style={{height: '100vh'}}>
                {isAuthenticated && user?.resume_in_draft_mode === false ? <Sidebar /> : null}

                <div className="d-flex flex-column container">
                    {
                        isAuthenticated ? (
                            typeof user?.resume_in_draft_mode === 'undefined' || user?.resume_in_draft_mode === true ? <Resume /> :  <Dashboard />
                        ) : <Auth /> }
                </div>
            </div>
        </>
    );
}

export default Home;
