import * as React from 'react';
import Experiences from "./Experiences/Experiences";
import Skills from "./Skills/Skills";
import Education from "./Education/Education";
import ResumeHeader from "./Contact/ResumeHeader";
import Summaries from "./Summary/Summaries";

const ResumeSwitcher = ({progress, updateUser, updateUserResult}) => {
    const switcher = (progress) => {
        switch(progress) {
            case 'experience':
                // experience block
                return <Experiences updateUser={updateUser} updateUserResult={updateUserResult} />;
            case 'skills':
                // skills block
                return <Skills updateUser={updateUser} updateUserResult={updateUserResult} />;
            case 'education':
                // education block
                return <Education updateUser={updateUser} updateUserResult={updateUserResult} />;
            case 'summary':
                // summary block
                return <Summaries updateUser={updateUser} updateUserResult={updateUserResult} />;
            default:
            // heading block
                return <ResumeHeader />;
        }
    }

    return switcher(progress);
}

export default ResumeSwitcher;
