import * as React from 'react';
import {useGetResumeSummaryQuery} from "../../../api/apiSlice";
import {useSelector} from "react-redux";
import SummaryForm from "./SummaryForm";
import DisplaySummary from "./DisplaySummary";

const Summary = ({updateUser, updateUserResult}) => {
    const {user, token} = useSelector(state => state.login);
    const {activeSummaryID, showSummaryForm} = useSelector(state => state.resume);
    const {
        data: resumeSummary,
        isFetching: isFetchingResumeSummary,
        refetch: reFetchSummary
    } = useGetResumeSummaryQuery({
        "token": token,
        "user_id": user?.uuid
    }, {skip: false});

    const displaySummaryForms = (summaries) => {
        return summaries?.length > 0 ?
            summaries.map((summary, index) => {
                return(
                    typeof activeSummaryID !== 'undefined' && activeSummaryID === summary?.uuid ? (
                        <SummaryForm key={index} summary={summary} reFetchSummary={reFetchSummary} updateUserResult={updateUserResult} />
                    ): <DisplaySummary summary={summary} key={index} />
                );
            })
            : <SummaryForm reFetchSummary={reFetchSummary} updateUserResult={updateUserResult} />;
    }

    const summariesContainer = (summaries) => {
        return(
            <div className="container mt-6">
                {displaySummaryForms(summaries)}
                {summaries?.length > 0 && !showSummaryForm ? (
                    <div className="float-end mt-4">
                        <button
                            className="btn btn-primary"
                            onClick={() => {
                                updateUser({
                                    "token": token,
                                    "body": {
                                        "uuid": user?.uuid,
                                        "email": user?.email ? user.email : '',
                                        "first_name": user?.first_name ? user.first_name : '',
                                        "last_name": user?.last_name ? user.last_name : '',
                                        "resume_url": user?.resume_url ? user.resume_url : '',
                                        "resume_in_draft_mode": false,
                                        "progress": 'completed',
                                        "tokenized": false,
                                        "tokenize": true,
                                        "linked_resources": summaries?.map((summary) => {return summary?.uuid}),
                                    }
                                });
                            }}>
                            {
                                updateUserResult?.isLoading ? (
                                    <div className="p-2">
                                        <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                                        Finish
                                    </div>
                                ): 'Finish'
                            }
                        </button>
                    </div>
                ): null}
                {showSummaryForm && <SummaryForm num_summaries={summaries?.length} reFetchSummary={reFetchSummary} updateUserResult={updateUserResult} />}
            </div>
        )
    };
    return(
        <>
            {
                isFetchingResumeSummary ? (
                    <div className="p-2">
                        <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                        Working...
                    </div>
                ) : summariesContainer(resumeSummary)
            }
        </>
    );
}

export default Summary;
