import * as React from 'react';
import {Field, Form} from "react-final-form";
import {useSelector} from "react-redux";
import {useGetJobByIDQuery, useSaveJobsMutation, useTokenizeRecourseMutation} from "../../../api/apiSlice";
import { Editor } from '@tinymce/tinymce-react';
import {merge, PUBLIC_URL} from "../../../constants";
import "react-datepicker/dist/react-datepicker.css";
import {useNavigate, useParams} from "react-router-dom";
import isURL from 'validator/lib/isURL';

const JobForm = () => {
    const {user, token} = useSelector(state => state.login);
    const [saveJob, saveJobResult] = useSaveJobsMutation();
    const [tokenizeResource, tokenizeResourceResult] = useTokenizeRecourseMutation();
    const editorRef = React.useRef(null);
    const [editorInitialized, setEditorInitialized] = React.useState(false);
    const editorContainer = editorInitialized ? 'd-block' :  'd-none';
    const {uuid} = useParams();
    const navigate = useNavigate();

    const {
        data: job,
        isFetching,
    } = useGetJobByIDQuery({
        "token": token,
        "uuid": uuid
    }, {skip: typeof uuid === 'undefined'});

    const submit = (values) => {
        const linked_resources = typeof job?.linked_resources !== 'undefined' && job?.linked_resources?.length > 0 ? merge([user?.uuid], job?.linked_resources) : [user?.uuid];
        if (typeof values?.title !== 'undefined' && values?.title?.trim() !== ''
            && typeof values?.description !== 'undefined' && values?.description?.trim() !== ''
            && typeof values?.url !== 'undefined' && values?.url?.trim() !== '' && isURL(values?.url)) {
            saveJob({
                "token": token,
                "method": values?.uuid !== '' ? 'PUT' : 'POST',
                "body": Object.assign({}, values, {"linked_resources": linked_resources})
            });
        }
    };

    const validate = values => {
        let errors = {};
        if (!values?.url || values?.url?.trim() === "" || !isURL(values?.url)) {
            errors.url = "Please enter a valid HTTP job listing url."
        }
        if (!values?.title || values?.title?.trim() === "") {
            errors.title = "You forgot to enter the job title."
        }
        if (!values?.description || values?.description?.trim() === "") {
            errors.description = "You forgot to enter the job description."
        }
        return errors;
    };

    React.useEffect(() => {
        if (saveJobResult?.status === 'fulfilled') {
            tokenizeResource({
                "resource_id": saveJobResult?.data?.uuid,
                "field": "description",
            });
        }
    }, [saveJobResult?.data?.uuid, saveJobResult?.status, tokenizeResource]);


    React.useEffect(() => {
        if (tokenizeResourceResult?.status === 'fulfilled') {
            // redirect to job page jobs/:uuid
            window.location.href = '/jobs/' + saveJobResult?.data?.uuid;
        }
    }, [tokenizeResourceResult?.status, saveJobResult?.data?.uuid]);

    return(
        <div className="mt-4 card">
            <div className="card-header">
                <h4>
                    {typeof job?.uuid !== 'undefined' ? 'Edit': 'New'} Job
                </h4>
                {
                    typeof job?.uuid !== 'undefined' ? (
                        <button
                            type="button"
                            className="btn-close float-end"
                            aria-label="Close"
                            onClick={() => {
                                navigate('/jobs/' + job?.uuid);
                            }}>
                        </button>
                    ): null
                }

            </div>

            <Form
                onSubmit={submit}
                validate={validate}
                initialValues={{
                    uuid: typeof job?.uuid !== 'undefined' ? job?.uuid : '',
                    url: typeof job?.url !== 'undefined' ? job?.url : '',
                    description: typeof job?.description !== 'undefined' ? job?.description : '',
                    cover_letter: typeof job?.cover_letter !== 'undefined' ? job?.cover_letter : '',
                    title: typeof job?.title !== 'undefined' ? job?.title : '',
                }}
                render={( {handleSubmit, form} ) => {
                    return(
                        <form onSubmit={handleSubmit}>
                            <div className={editorContainer}>
                                { saveJobResult?.isLoading || tokenizeResourceResult?.isLoading ? (
                                    <div className="p-2">
                                        <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                                        Working...
                                    </div>
                                ): null }
                                <div className="card-body">
                                    <div className="mb-4">
                                        <Field
                                            name="url"
                                            render={({ input, meta }) => (
                                                <div className="mb-4 row">
                                                    <label className="col-sm-2 col-form-label fw-bold">Listing URL</label>
                                                    <div className="col-sm-10">
                                                        <input
                                                            {...input}
                                                            autoComplete="off"
                                                            className="form-control"
                                                            placeholder="Job Listing URL"
                                                            disabled={saveJobResult?.isLoading || isFetching || tokenizeResourceResult?.isLoading}
                                                        />
                                                    </div>
                                                    {!saveJobResult?.isLoading ? (
                                                        meta.touched && meta.error && <p className="text-danger mt-2">{meta.error}</p>
                                                    ) : null}
                                                </div>
                                            )}
                                        />
                                    </div>

                                    <div className="mb-4">
                                        <Field
                                            name="title"
                                            render={({ input, meta }) => (
                                                <div className="mb-4 row">
                                                    <label className="col-sm-2 col-form-label fw-bold">Title</label>
                                                    <div className="col-sm-10">
                                                        <input
                                                            {...input}
                                                            autoComplete="off"
                                                            className="form-control"
                                                            placeholder="Job Title"
                                                            disabled={saveJobResult?.isLoading || isFetching || tokenizeResourceResult?.isLoading}
                                                        />
                                                    </div>
                                                    {!saveJobResult?.isLoading ? (
                                                        meta.touched && meta.error && <p className="text-danger mt-2">{meta.error}</p>
                                                    ) : null}
                                                </div>
                                            )}
                                        />
                                    </div>
                                    <div className="mb-4">
                                        <Field
                                            name="description"
                                            disabled={saveJobResult?.isLoading || isFetching || tokenizeResourceResult?.isLoading}
                                            render={({ input, meta }) => (
                                                <div className="mb-4 row">
                                                    <label className="col-sm-2 col-form-label fw-bold">Description</label>
                                                    <div className="col-sm-10">
                                                        <Editor
                                                            tinymceScriptSrc={PUBLIC_URL + '/tinymce/tinymce.min.js'}
                                                            onInit={(evt, editor) => {
                                                                editorRef.current = editor;
                                                                setEditorInitialized(true);
                                                            }}
                                                            value={input?.value}
                                                            init={{
                                                                height: 200,
                                                                menubar: false,
                                                                plugins: [
                                                                    'advlist autolink lists link image charmap print preview anchor',
                                                                    'searchreplace visualblocks code fullscreen',
                                                                    'insertdatetime media table paste code help wordcount'
                                                                ],
                                                                toolbar: 'undo redo | formatselect | ' +
                                                                    'bold italic backcolor | alignleft aligncenter ' +
                                                                    'alignright alignjustify | bullist numlist outdent indent | ' +
                                                                    'removeformat ',
                                                                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                                            }}
                                                            onEditorChange={(content, editor) => {
                                                                input.onChange(content);
                                                            }}
                                                        />
                                                    </div>
                                                    {!saveJobResult?.isLoading ? (
                                                        meta.touched && meta.error && <span className="text-danger">{meta.error}</span>
                                                    ) : null}
                                                </div>
                                            )}
                                        />
                                    </div>
                                </div>
                                <div className="card-footer bg-white">
                                    <button
                                        className="btn btn-secondary mb-2 float-end"
                                        disabled={saveJobResult?.isLoading || isFetching || tokenizeResourceResult?.isLoading}
                                        onClick={() => {
                                            submit();
                                        }}
                                    >
                                        {
                                            saveJobResult?.isLoading ? (
                                                <div className="p-2">
                                                    <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                                                    Saving...
                                                </div>
                                            ): "Save"
                                        }
                                    </button>
                                </div>
                            </div>
                        </form>
                    )
                }}
            />
        </div>
    );
}

export default JobForm;
