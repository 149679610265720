import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import AWS from "aws-sdk";
import {v4 as uuidv4} from 'uuid';

export const uploadFileToS3 = createAsyncThunk(
    's3Upload/uploadFileToS3',
    async (payload, { rejectWithValue, dispatch }) => {
        AWS.config.credentials = new AWS.CognitoIdentityCredentials({
            IdentityPoolId: payload?.settings?.IdentityPoolId,
            IdentityId: payload?.settings?.IdentityId,
            Logins: {
                'cognito-identity.amazonaws.com': payload?.settings?.Token
            }
        });

        AWS.config.update( { region: payload?.settings?.region, credentials: AWS.config.credentials });
        const s3 = new AWS.S3({apiVersion: '2006-03-01'});
        const fileIdentifier = uuidv4() +  payload?.file.name.substr((payload?.file.name.lastIndexOf('.')));
        const params = {
            Bucket: payload?.settings?.bucket + "/private/" + payload?.settings?.IdentityId,
            Key: fileIdentifier,
            Body: payload?.file,
            ACL: "public-read",
            ContentType: payload?.file.type,
            IdentityPoolId: payload?.settings?.IdentityPoolId,
        };

        const options = {
            partSize: payload?.file.size * 1024 * 1024,
            queueSize: 1
        };

        try {
            return await s3.upload(params, options).on('httpUploadProgress', function(evt) {
                dispatch(updateProgress({progress: parseInt((evt.loaded * 100) / evt.total)+'%'}));
            }).promise();
        } catch (error) {
            return rejectWithValue(error);
        }
    }
);

const resumeSlice = createSlice({
    name: 'resume',
    initialState: {
        showFileDropZone: false,
        files: [],
        purchases: [],
        uploadedFileData: undefined,
        error: undefined,
        isUploading: false,
        showFileUploadSuccess: false,
        progress: '0%',
        showProgressBar: false,
        cart: {},
        showCart: false,
        activeExperienceID: undefined,
        showExperienceForm: false,
        activeSkillID: undefined,
        showSkillForm: false,
        activeSchoolID: undefined,
        showSchoolForm: false,
        activeSummaryID: undefined,
    },
    reducers: {
        updateFiles(state, action) {
            const { files } = action.payload;
            state.files = files;
        },
        updateProgress(state, action) {
            const { progress } = action.payload;
            state.progress = progress;
        },
        resetUploadProgress(state) {
            state.progress = '0%';
        },
        addToCart(state, action) {
            const { job } = action.payload;
            state.cart['job_' + job.id] = job;
            localStorage.setItem('cart', JSON.stringify(state.cart));
        },
        removeFromCart(state, action) {
            const { job } = action.payload;
            if (typeof state.cart['job_' + job.id] !== 'undefined') {
                delete state.cart['job_' + job.id]
                localStorage.setItem('cart', JSON.stringify(state.cart));
            }
            Object.keys(state.cart)?.length === 0 && localStorage.removeItem('cart');
        },
        toggleShowCart(state, action) {
            const { showCart } = action.payload;
            state.showCart = showCart;
        },
        showExperienceForm(state, action) {
            const { activeExperienceID } = action.payload;
            state.activeExperienceID = activeExperienceID;
        },
        toggleNewExperienceForm(state, action) {
            const { showExperienceForm } = action.payload;
            state.showExperienceForm = showExperienceForm;
        },
        showSkillForm(state, action) {
            const { activeSkillID } = action.payload;
            state.activeSkillID = activeSkillID;
        },
        toggleNewSkillForm(state, action) {
            const { showSkillForm } = action.payload;
            state.showSkillForm = showSkillForm;
        },
        showCoverLetterForm(state, action) {
            const { showCoverLetterForm } = action.payload;
            state.showCoverLetterForm = showCoverLetterForm;
        },
        showSchoolForm(state, action) {
            const { activeSchoolID } = action.payload;
            state.activeSchoolID = activeSchoolID;
        },
        toggleNewSchoolForm(state, action) {
            const { showSchoolForm } = action.payload;
            state.showSchoolForm = showSchoolForm;
        },
        showSummaryForm(state, action) {
            const { activeSummaryID } = action.payload;
            state.activeSummaryID = activeSummaryID;
        },
        toggleNewSummaryForm(state, action) {
            const { showSummaryForm } = action.payload;
            state.showSummaryForm = showSummaryForm;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(uploadFileToS3.pending, (state) => {
                state.showFileDropZone = false;
                state.isUploading = true;
                state.error = undefined;
                state.showProgressBar = true;
            })
            .addCase(uploadFileToS3.fulfilled, (state, action) => {
                state.isUploading = false;
                state.uploadedFileData = Object.assign(
                    {},
                    {"name": state.files[0]["name"]},
                    {
                        "location": action.payload.Location,
                        "key": action?.payload?.Key,
                        "bucket": action?.payload?.Bucket
                    });
                state.files = [];
                state.showProgressBar = false;
            })
            .addCase(uploadFileToS3.rejected, (state, action) => {
                state.isUploading = false;
                state.showFileDropZone = true;
                state.error = action.payload;
                state.showProgressBar = false;
            })
    },
});

export const {
    updateFiles,
    updateProgress,
    resetUploadProgress,
    showExperienceForm,
    showSummaryForm,
    showCoverLetterForm,
    addToCart,
    removeFromCart,
    toggleShowCart,
    toggleNewExperienceForm,
    showSkillForm,
    toggleNewSkillForm,
    showSchoolForm,
    toggleNewSchoolForm,
    toggleNewSummaryForm
} = resumeSlice.actions;
export default resumeSlice.reducer;
