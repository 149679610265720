import * as React from 'react';
import {useGetResumeSkillsQuery} from "../../../api/apiSlice";
import {useSelector} from "react-redux";
import SkillForm from "./SkillForm";
import DisplaySkill from "./DisplaySkill";

const Skills = ({updateUser, updateUserResult}) => {
    const {user, token} = useSelector(state => state.login);
    const {activeSkillID, showSkillForm} = useSelector(state => state.resume);
    const {
        data: resumeSkills,
        isFetching: isFetchingResumeSkill,
        refetch: reFetchSkills
    } = useGetResumeSkillsQuery({
        "token": token,
        "user_id": user?.uuid
    }, {skip: false});

    const displaySkillForms = (skills) => {
        return skills?.length > 0 ?
            skills.map((skill, index) => {
                return(
                    typeof activeSkillID !== 'undefined' && activeSkillID === skill?.uuid ? (
                        <SkillForm key={index} skill={skill} reFetchSkills={reFetchSkills} updateUserResult={updateUserResult} />
                    ): <DisplaySkill skill={skill} key={index} />
                );
            })
            : <SkillForm reFetchSkills={reFetchSkills} updateUserResult={updateUserResult} />;
    }

    const skillsContainer = (skills) => {
        return(
            <div className="container mt-6">
                {displaySkillForms(skills)}
                {skills?.length > 0 && !showSkillForm ? (
                    <div className="float-end mt-4">
                        <button
                            className="btn btn-primary"
                            onClick={() => {
                                updateUser({
                                    "token": token,
                                    "body": {
                                        "uuid": user?.uuid,
                                        "email": user?.email ? user.email : '',
                                        "first_name": user?.first_name ? user.first_name : '',
                                        "last_name": user?.last_name ? user.last_name : '',
                                        "resume_url": user?.resume_url ? user.resume_url : '',
                                        "resume_in_draft_mode": typeof user?.resume_in_draft_mode !== "undefined" ? user.resume_in_draft_mode : true,
                                        "progress": 'education',
                                        "tokenized": false,
                                        "tokenize": true,
                                        "linked_resources": skills?.map((skill) => {return skill?.uuid}),
                                    }
                                });
                            }}>
                            {
                                updateUserResult?.isLoading ? (
                                    <div className="p-2">
                                        <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                                        Continue to Education
                                    </div>
                                ): 'Continue to Education'
                            }
                        </button>
                    </div>
                ): null}
                {showSkillForm && <SkillForm num_skills={skills?.length} reFetchSkills={reFetchSkills} updateUserResult={updateUserResult} />}
            </div>
        )
    };
    return(
        <>
            {
                isFetchingResumeSkill ? (
                    <div className="p-2">
                        <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                        Working...
                    </div>
                ) : skillsContainer(resumeSkills)
            }
        </>
    );
}

export default Skills;
