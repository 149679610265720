import * as React from 'react';
import {Field, Form} from "react-final-form";
import {useDispatch, useSelector} from "react-redux";
import {useSaveResumeSummaryMutation, useTokenizeRecourseMutation} from "../../../api/apiSlice";
import { Editor } from '@tinymce/tinymce-react';
import {PUBLIC_URL} from "../../../constants";
import "react-datepicker/dist/react-datepicker.css";
import {showSummaryForm, toggleNewSummaryForm} from "../../../reducers/resumeSlice";

const SummaryForm = ({summary, reFetchSummary, updateUserResult}) => {
    const {user, token} = useSelector(state => state.login);
    const [saveSummary, saveResumeSummaryResult] = useSaveResumeSummaryMutation();
    const [tokenizeResource, tokenizeResourceResult] = useTokenizeRecourseMutation();
    const editorRef = React.useRef(null);
    const [editorInitialized, setEditorInitialized] = React.useState(false);
    const editorContainer = editorInitialized ? 'd-block' :  'd-none';
    const dispatch = useDispatch();

    const submit = (values) => {
        if (typeof values?.summary !== 'undefined' && values?.summary?.trim() !== '') {
            saveSummary({
                "token": token,
                "method": values?.uuid !== '' ? 'PUT' : 'POST',
                "body": Object.assign({}, values, {"linked_resources": [user?.uuid]})
            });
        }
    };

    const validate = values => {
        let errors = {};
        if (!values?.summary || values?.summary?.trim() === "") {
            errors.summary = "You forgot to enter your summary."
        }
        return errors;
    };

    React.useEffect(() => {
        if (saveResumeSummaryResult?.status === 'fulfilled') {
            tokenizeResource({
                "resource_id": saveResumeSummaryResult?.data?.uuid,
                "field": "summary",
            });
        }
    }, [saveResumeSummaryResult?.data?.uuid, saveResumeSummaryResult?.status, tokenizeResource]);

    React.useEffect(() => {
        if(tokenizeResourceResult?.status === 'fulfilled') {
            reFetchSummary();
            dispatch(showSummaryForm({
                "activeSummaryID": undefined,
            }));
            dispatch(toggleNewSummaryForm({
                "showSummaryForm": false,
            }));
        }
    }, [dispatch, reFetchSummary, tokenizeResourceResult?.status]);

    return(
        <div className="mt-4 card">
            {
                typeof summary?.uuid !== 'undefined' ? (
                    <div className="card-header">
                        <button
                            type="button"
                            className="btn-close float-end"
                            aria-label="Close"
                            onClick={() => {
                                dispatch(showSummaryForm({
                                    "activeSummaryID": undefined,
                                }));
                                dispatch(toggleNewSummaryForm({
                                    "showSummaryForm": false,
                                }));
                            }}>
                        </button>
                    </div>
                ): null
            }
            <Form
                onSubmit={submit}
                validate={validate}
                initialValues={{
                    uuid: typeof summary?.uuid !== 'undefined' ? summary?.uuid : '',
                    summary: typeof summary?.summary !== 'undefined' ? summary?.summary : '',
                }}
                render={( {handleSubmit, form} ) => {
                    return(
                        <form onSubmit={handleSubmit}>
                            <div className={editorContainer}>
                                { saveResumeSummaryResult?.isLoading || updateUserResult?.isLoading ? (
                                    <div className="p-2">
                                        <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                                        Working...
                                    </div>
                                ): null }
                                <div className="card-body">
                                    <div className="mb-4">
                                        <Field
                                            name="summary"
                                            render={({ input, meta }) => (
                                                <div className="mb-4">
                                                    <label className="form-label fw-bold">Summary?</label>
                                                    <div className="mb-4">
                                                        <Editor
                                                            tinymceScriptSrc={PUBLIC_URL + '/tinymce/tinymce.min.js'}
                                                            onInit={(evt, editor) => {
                                                                editorRef.current = editor;
                                                                setEditorInitialized(true);
                                                            }}
                                                            value={input?.value}
                                                            init={{
                                                                height: 200,
                                                                menubar: false,
                                                                plugins: [
                                                                    'advlist autolink lists link image charmap print preview anchor',
                                                                    'searchreplace visualblocks code fullscreen',
                                                                    'insertdatetime media table paste code help wordcount'
                                                                ],
                                                                toolbar: 'undo redo | formatselect | ' +
                                                                    'bold italic backcolor | alignleft aligncenter ' +
                                                                    'alignright alignjustify | bullist numlist outdent indent | ' +
                                                                    'removeformat ',
                                                                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                                            }}
                                                            onEditorChange={(content, editor) => {
                                                                input.onChange(content);
                                                            }}
                                                        />
                                                    </div>
                                                    {!updateUserResult?.isLoading ? (
                                                        meta.touched && meta.error && <span className="text-danger">{meta.error}</span>
                                                    ) : null}
                                                </div>
                                            )}
                                        />
                                    </div>
                                </div>
                                <div className="card-footer bg-white">
                                    <button
                                        className="btn btn-secondary mb-2 float-end"
                                        disabled={saveResumeSummaryResult?.isLoading || updateUserResult?.isLoading}
                                        onClick={() => {
                                            submit();
                                        }}
                                    >
                                        {
                                            saveResumeSummaryResult?.isLoading || updateUserResult?.isLoading ? (
                                                <div className="p-2">
                                                    <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                                                    Saving...
                                                </div>
                                            ): "Save"
                                        }
                                    </button>
                                </div>
                            </div>
                        </form>
                    )
                }}
            />
        </div>
    );
}

export default SummaryForm;
