import * as React from 'react';
import {Field, Form} from "react-final-form";
import {useDispatch, useSelector} from "react-redux";
import {useSaveResumeEducationMutation} from "../../../api/apiSlice";
import {areas_of_study, degrees} from "../../../constants";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import {showSchoolForm, toggleNewSchoolForm} from "../../../reducers/resumeSlice";
import 'react-bootstrap-typeahead/css/Typeahead.css';

const EducationForm = ({school, reFetchEducation, updateUserResult}) => {
    const {user, token} = useSelector(state => state.login);
    const [saveEducation, saveResumeEducationResult] = useSaveResumeEducationMutation();
    const dispatch = useDispatch();

    const submit = (values) => {
        if (values?.school_name?.trim() !== '' && values?.graduation_date) {
            dispatch(toggleNewSchoolForm({
                "showSchoolForm": false,
            }));
            saveEducation({
                "token": token,
                "method": values?.uuid !== '' ? 'PUT' : 'POST',
                "body": Object.assign({}, values, {"linked_resources": [user?.uuid]})
            });
        }
    };

    const validate = values => {
        let errors = {};
        if (!values?.school_name || values?.school_name?.trim() === "") {
            errors.school_name = "You forgot to enter your school name."
        }

        if (!values?.graduation_date || values?.graduation_date === "") {
            errors.graduation_date = "You forgot enter the graduation date. " +
                "If you have not graduated enter the date expect to graduate."
        }
        return errors;
    };

    React.useEffect(() => {
        if(saveResumeEducationResult?.status === 'fulfilled') {
            reFetchEducation();
            dispatch(showSchoolForm({
                "activeEducationID": undefined,
            }));
        }
    });
    return(
        <div className="mt-4 card">
            <div className="card-header">
                <button
                    type="button"
                    className="btn-close float-end"
                    aria-label="Close"
                    onClick={() => {
                        dispatch(showSchoolForm({
                            "activeEducationID": undefined,
                        }));
                        dispatch(toggleNewSchoolForm({
                            "showSchoolForm": false,
                        }));
                    }}>
                </button>
            </div>
            <Form
                onSubmit={submit}
                validate={validate}
                initialValues={{
                    uuid: typeof school?.uuid !== 'undefined' ? school?.uuid : '',
                    school_name: typeof school?.school_name !== 'undefined' ? school?.school_name : '',
                    school_location: typeof school?.school_location !== 'undefined' ? school?.school_location : '',
                    degree_type: typeof school?.degree_type !== 'undefined' ? school?.degree_type : '',
                    area_of_study: typeof school?.area_of_study !== 'undefined' ? school?.area_of_study : '',
                    graduation_date: typeof school?.graduation_date !== 'undefined' ? school?.graduation_date : ''
                }}
                render={( {handleSubmit, form} ) => {
                    return(
                        <form onSubmit={handleSubmit}>
                            <>
                                { saveResumeEducationResult?.isLoading || updateUserResult?.isLoading ? (
                                    <div className="p-2">
                                        <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                                        Working...
                                    </div>
                                ): null }
                                <div className="card-body">
                                    <div className="mb-4">
                                        <Field
                                            name="school_name"
                                            render={({ input, meta }) => (
                                                <div className="mb-4 row">
                                                    <label className="col-sm-2 col-form-label fw-bold">School</label>
                                                    <div className="col-sm-10">
                                                        <input
                                                            {...input}
                                                            autoComplete="off"
                                                            className="form-control"
                                                            placeholder="School Name"
                                                            disabled={saveResumeEducationResult?.isLoading || updateUserResult?.isLoading}
                                                        />
                                                    </div>
                                                    {!saveResumeEducationResult?.isLoading ? (
                                                        meta.touched && meta.error && <p className="text-danger mt-2">{meta.error}</p>
                                                    ) : null}
                                                </div>
                                            )}
                                        />
                                    </div>
                                    <div className="mb-4">
                                        <Field
                                            name="school_location"
                                            render={({ input, meta }) => (
                                                <div className="mb-4 row">
                                                    <label className="col-sm-2 col-form-label fw-bold">Location</label>
                                                    <div className="col-sm-10">
                                                        <input
                                                            {...input}
                                                            autoComplete="off"
                                                            className="form-control"
                                                            placeholder="School Location"
                                                            disabled={saveResumeEducationResult?.isLoading || updateUserResult?.isLoading}
                                                        />
                                                    </div>
                                                    {!saveResumeEducationResult?.isLoading ? (
                                                        meta.touched && meta.error && <p className="text-danger mt-2">{meta.error}</p>
                                                    ) : null}
                                                </div>
                                            )}
                                        />
                                    </div>
                                    <div className="mb-4">
                                        <div className="mb-4 row">
                                            <label className="col-sm-2 col-form-label fw-bold">Degree</label>
                                            <div className="col-sm-10">
                                                <Field
                                                    name="degree_type"
                                                    component="select"
                                                    disabled={saveResumeEducationResult?.isLoading || updateUserResult?.isLoading}
                                                    className="form-select">
                                                    <option>Select a degree</option>
                                                    <>
                                                        {degrees?.map((degree, index) => {
                                                            return <option key={index} value={degree?.degree}>{degree?.degree}</option>
                                                        })}
                                                    </>
                                                </Field>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mb-4">
                                        <div className="mb-4 row">
                                            <label className="col-sm-2 col-form-label fw-bold">Area of Study</label>
                                            <div className="col-sm-10">
                                                <Field
                                                    name="area_of_study"
                                                    component="select"
                                                    disabled={saveResumeEducationResult?.isLoading || updateUserResult?.isLoading}
                                                    className="form-select">
                                                    <option>Select area of study</option>
                                                    <>
                                                        {areas_of_study?.map((area, index) => {
                                                            return <option key={index} value={area?.field}>{area?.field}</option>
                                                        })}
                                                    </>
                                                </Field>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mb-4">
                                        <Field
                                            name="graduation_date"
                                            render={({ input, meta }) => (
                                                <div className="mb-4 row">
                                                    <label className="col-sm-2 col-form-label fw-bold">Graduation</label>
                                                    <div className="col-sm-10">
                                                        <DatePicker
                                                            showIcon={true}
                                                            selected={input?.value !== '' ? moment(input?.value).toDate() : new Date()}
                                                            onChange={(date) => {
                                                                input.onChange(date);
                                                            }}
                                                            showMonthYearPicker
                                                            dateFormat="MM/yyyy"
                                                        />
                                                        <p className="help-text mt-2 text-muted">If you have not graduated provide the date you expect to graduate.</p>
                                                    </div>
                                                    {!saveResumeEducationResult?.isLoading ? (
                                                        meta.touched && meta.error && <p className="text-danger mt-2">{meta.error}</p>
                                                    ) : null}
                                                </div>
                                            )}
                                        />
                                    </div>
                                </div>
                                <div className="card-footer bg-white">
                                    <button
                                        className="btn btn-secondary mb-2 float-end"
                                        disabled={saveResumeEducationResult?.isLoading || updateUserResult?.isLoading}
                                        onClick={() => {
                                            submit();
                                        }}
                                    >
                                        {
                                            saveResumeEducationResult?.isLoading || updateUserResult?.isLoading ? (
                                                <div className="p-2">
                                                    <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                                                    Saving...
                                                </div>
                                            ): "Save"
                                        }
                                    </button>
                                </div>
                            </>
                        </form>
                    )
                }}
            />
        </div>
    );
}

export default EducationForm;
