import * as React from "react";
import moment from "moment";
import * as Icon from "react-bootstrap-icons";
import {useDispatch} from "react-redux";
import {showSchoolForm} from "../../../reducers/resumeSlice";

const DisplaySchool = ({school, borderless=false, editable=true, allowRegeneration=false}) => {
    const dispatch = useDispatch();
    const className = borderless ? "card border-0" : "card mt-4";
    const headerClassName = borderless ? "card-header bg-white" : "card-header";
    const footerClassName = borderless ? "card-footer bg-white" : "card-footer";
    return(
        <div className={className}>
            <div className={headerClassName}>
                {school?.degree_type}{school?.degree_type !== '' ? ', ' : null}
                {school?.area_of_study}{school?.area_of_study !== '' ? ', ' : null}
                {school?.school_name}
            </div>
            <div className="card-body">
                <div className="card-title">
                    {school?.school_location} {school?.school_location !== '' ? ' | ' : null} {moment(school?.graduation_date).format('MM/YYYY')}
                </div>
            </div>
            <div className={footerClassName}>
                {
                    allowRegeneration
                    &&
                    <button
                        className="btn btn-light float-end"
                        onClick={() => {
                            console.log("Regenerate Education");
                        }}><Icon.ArrowRepeat /> <Icon.Robot /></button>
                }
                {
                    editable
                    &&
                    <button
                        className="btn btn-light float-end me-2"
                        onClick={() => {
                            dispatch(showSchoolForm({
                                "activeSchoolID": school?.uuid,
                            }))
                        }}><Icon.PencilSquare /></button>
                }
            </div>
        </div>
    );
}

export default DisplaySchool;
