import * as React from 'react';
import {useDispatch, useSelector} from "react-redux";
import ResumeSwitcher from "./ResumeSwitcher";
import ResumePageTitle from "./ResumePageTitle";
import {useUpdateUserMutation} from "../../api/apiSlice";
import {loginUser} from "../../reducers/loginSlice";
import jwt_decode from "jwt-decode";

const Resume = () => {
    const {user} = useSelector(state => state.login);
    const progress = user?.progress ? user?.progress : 'heading';
    const headingClassName = progress === 'heading' ? 'nav-link active': 'nav-link disabled';
    const experienceClassName = progress === 'experience' ? 'nav-link active': 'nav-link disabled';
    const skillsClassName = progress === 'skills' ? 'nav-link active': 'nav-link disabled';
    const educationClassName = progress === 'education' ? 'nav-link active': 'nav-link disabled';
    const summaryClassName = progress === 'summary' ? 'nav-link active': 'nav-link disabled';
    const [updateUser, updateUserResult] = useUpdateUserMutation();
    const dispatch = useDispatch();

    React.useEffect(() => {
        if (updateUserResult?.status === 'fulfilled') {
            dispatch(loginUser({
                "token": updateUserResult?.data?.token,
                "user": jwt_decode(updateUserResult?.data?.token)
            }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateUserResult?.status]);

    return(
        <div className="container bg-white">
            <div className="container col-xxl-8 px-4 py-5">
                <h4 className="mt-4 mb-4">Welcome, {user?.first_name}. Let's get started with your resume</h4>
                {ResumePageTitle({progress})}
                <nav className="nav nav-pills nav-justified mt-4 mb-4">
                    <button className={headingClassName} aria-current="page">Contact</button>
                    <button className={experienceClassName} aria-disabled="true">Work History</button>
                    <button className={skillsClassName} aria-disabled="true">Skills</button>
                    <button className={educationClassName} aria-disabled="true">Education</button>
                    <button className={summaryClassName} aria-disabled="true">Summary</button>
                </nav>
                <ResumeSwitcher progress={progress} updateUser={updateUser} updateUserResult={updateUserResult} />
            </div>
        </div>
    );
}

export default Resume;
