import * as React from "react";
import * as Icon from "react-bootstrap-icons";
import {useDispatch, useSelector} from "react-redux";
import {showCoverLetterForm} from "../../../reducers/resumeSlice";
import {useGenerateMutation} from "../../../api/apiSlice";
import {stripHtml} from "string-strip-html";

const DisplayCoverLetter = ({job, reFetchJob, summary, lastExperience, borderless=false, editable=true, allowRegeneration=false}) => {
    const {token} = useSelector(state => state.login);
    const dispatch = useDispatch();
    const className = borderless ? "card border-0" : "card mt-4";
    const footerClassName = borderless ? "card-footer bg-white" : "card-footer";
    const [generate, generateResult] = useGenerateMutation();
    const description = typeof job?.description !== 'undefined' ? stripHtml(job?.description)?.result : '';
    const parsedDescription = description?.split('.');
    const context = parsedDescription?.[0] + parsedDescription?.[1] + parsedDescription?.[2];
    const summaryText = summary?.summary;
    const lastExperienceText = 'Latest experience: ' + lastExperience?.title + ' at ' + lastExperience?.company + '. Job description: ' + lastExperience?.job_description;
    const intervalRef = React.useRef(null);
    const [isGenerating, setIsGenerating] = React.useState(false);

    React.useEffect(() => {
        if (generateResult?.status === 'fulfilled') {
            // Set up polling every 30 seconds
            intervalRef.current = setInterval(reFetchJob, 5000);
        }
    }, [generateResult?.status, reFetchJob]);

    React.useEffect(() => {
        if (generateResult?.status === 'pending') {
            setIsGenerating(true);
        }
    }, [generateResult?.status]);

    React.useEffect(() => {
        if (job?.cover_letter !== '_llm_processing') {
            setIsGenerating(false);
            clearInterval(intervalRef.current);
        }
    }, [job?.cover_letter]);

    return(
        <div className={className}>
            <div className="card-body" style={{height:'65vh', overflow:"auto"}}>
                {
                    isGenerating ? (
                        <button className="btn btn-light bg-white" type="button" disabled>
                            <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                            <span className="ms-2 me-2"><Icon.Robot /></span>Working! It might take a few minutes...
                        </button>
                    ): (
                        <div className="card-text" dangerouslySetInnerHTML={{__html: job?.cover_letter}}></div>
                    )
                }
            </div>
            <div className={footerClassName}>
                {
                    allowRegeneration
                    &&
                    <button
                        className="btn btn-light float-end"
                        disabled={isGenerating}
                        onClick={() => {
                            let data = {
                                "field": "cover_letter",
                                "resource_id": job?.uuid,
                            };
                            data["prompts"] = [{
                                "assistant_content": context + summaryText,
                                "user_content": "Given the following context about a job: " + context +
                                    " Applicant resume summary: " +  summaryText + ". " + lastExperienceText +
                                    " Write a cover letter for the candidate personalized to increase the candidate's chances of getting hired. Do not embellish. Adhere strictly to the context. Please format your paragraphs with html paragraph tags",
                                "model": "gpt-4"
                            }];
                            generate({
                                "token": token,
                                "body": data
                            });
                        }}><Icon.ArrowRepeat /> <Icon.Robot /></button>
                }
                {
                    editable
                    &&
                    <button
                        className="btn btn-light float-end me-2"
                        disabled={isGenerating}
                        onClick={() => {
                            dispatch(showCoverLetterForm({
                                "showCoverLetterForm": true,
                            }))
                        }}><Icon.PencilSquare /></button>
                }
            </div>
        </div>
    );
}

export default DisplayCoverLetter;
