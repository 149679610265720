import * as React from "react";
import {showSummaryForm} from "../../../reducers/resumeSlice";
import * as Icon from "react-bootstrap-icons";
import {useDispatch, useSelector} from "react-redux";
import {useGenerateMutation, useGetTokenizedResourceByIDQuery} from "../../../api/apiSlice";
import {stripHtml} from "string-strip-html";

const DisplaySummary = ({summary, reFetchSummary, borderless=false, editable=true, allowRegeneration=false, job=undefined}) => {
    const {token} = useSelector(state => state.login);
    const dispatch = useDispatch();
    const className = borderless ? "card border-0" : "card mt-4";
    const footerClassName = borderless ? "card-footer bg-white" : "card-footer";
    const [generate, generateResult] = useGenerateMutation();
    const { data: tokenizedSummary,
    } = useGetTokenizedResourceByIDQuery({
        "token": token,
        "uuid": summary?.uuid,
        "compare_resource_id": typeof job?.uuid !== 'undefined' ? job?.uuid : undefined,
    }, {skip: false});
    const description = typeof job?.description !== 'undefined' ? stripHtml(job?.description)?.result : '';
    const descriptionArr = description?.split('.');
    const parsedDescription = descriptionArr?.[0] + ' ' + descriptionArr?.[1] + ' ' + descriptionArr?.[2];
    const intervalRef = React.useRef(null);
    const [isGenerating, setIsGenerating] = React.useState(false);

    React.useEffect(() => {
       if (generateResult?.status === 'fulfilled') {
           // Set up polling every 30 seconds
           intervalRef.current = setInterval(reFetchSummary, 5000);
       }
    }, [generateResult?.status, reFetchSummary]);

    React.useEffect(() => {
        if (generateResult?.status === 'pending') {
            setIsGenerating(true);
        }
    }, [generateResult?.status]);

    React.useEffect(() => {
        if (summary?.summary !== '_llm_processing') {
            setIsGenerating(false);
            clearInterval(intervalRef.current);
        }
    }, [summary?.summary]);

    return(
        <div className={className}>
            <div className="card-body">
                {
                    isGenerating || summary?.summary === '_llm_processing' ? (
                        <button className="btn btn-light bg-white" type="button" disabled>
                            <span className="spinner-grow spinner-grow-sm" role="status" aria-hidden="true"></span>
                            <span className="ms-2 me-2"><Icon.Robot /></span>Working! It might take a few minutes...
                        </button>
                    ): (
                        <div className="card-text" dangerouslySetInnerHTML={{__html: summary?.summary}}></div>
                    )
                }
            </div>
            <div className={footerClassName}>
                {
                    allowRegeneration
                    &&
                    <button
                        className="btn btn-light float-end"
                        disabled={isGenerating}
                        onClick={() => {
                            let data = {
                                "field": "summary",
                                "resource_id": summary?.uuid,
                            };
                            let prompts = [];
                            tokenizedSummary?.sentences?.forEach((sentence) => {
                                let context = '';
                                for (let i = 0; i < sentence?.similarities?.length; i++) {
                                    if (typeof sentence?.similarities?.[i]?.sentence !== 'undefined' && sentence?.similarities?.[i]?.sentence !== '') {
                                        context += sentence?.similarities?.[i]?.sentence + ' ';
                                    }
                                }
                                if (context === '') {
                                    context = parsedDescription.trim();
                                }
                                prompts.push({
                                    "assistant_content": sentence.text,
                                    "user_content": "Given the following context about a job: " + context +
                                        " Rewrite this summary section of the job seeker's resume. Don't use 'I'. Be very concise. Avoid verbose language. Personalized it to increase the candidate's chances of getting hired: " + sentence?.text + " Please format your response using html paragraphs tags to indicate paragraphs. ",
                                    "model": "gpt-4"
                                });
                            });
                            if (prompts.length === 0) {
                                data["prompts"] = [{
                                    "assistant_content": parsedDescription,
                                    "user_content": "Given the following context about a job: " + parsedDescription +
                                        " Rewrite this summary section of the job seeker's resume. Don't use 'I'. Be very concise. Avoid verbose language. Personalized it to increase the candidate's chances of getting hired: " + summary?.summary + " Please format your response using html paragraphs tags to indicate paragraphs. ",
                                    "model": "gpt-4"
                                }];
                            } else {
                                data["prompts"] = prompts;
                            }
                            generate({
                                "token": token,
                                "body": data
                            });
                        }}><Icon.ArrowRepeat /> <Icon.Robot /></button>
                }
                {
                    editable
                    &&
                    <button
                        className="btn btn-light float-end me-2"
                        disabled={isGenerating}
                        onClick={() => {
                            dispatch(showSummaryForm({
                                "activeSummaryID": summary?.uuid,
                            }))
                        }}><Icon.PencilSquare /></button>
                }
            </div>
        </div>
    );
}

export default DisplaySummary;
