import * as React from 'react';
import {Field, Form} from "react-final-form";
import {useDispatch, useSelector} from "react-redux";
import {useSaveResumeExperienceMutation, useTokenizeRecourseMutation} from "../../../api/apiSlice";
import { Editor } from '@tinymce/tinymce-react';
import {PUBLIC_URL} from "../../../constants";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import {showExperienceForm, toggleNewExperienceForm} from "../../../reducers/resumeSlice";

const ExperienceForm = ({experience, num_experiences, reFetchExperiences, updateUserResult}) => {
    const {user, token} = useSelector(state => state.login);
    const [saveExperience, saveResumeExperienceResult] = useSaveResumeExperienceMutation();
    const [tokenizeResource] = useTokenizeRecourseMutation();
    const editorRef = React.useRef(null);
    const dispatch = useDispatch();

    const submit = (values) => {
        if (typeof values?.current_position !== 'undefined' && values?.current_position === true) {
            values.end_date = "";
        }

        if (typeof values?.current_position !== 'undefined' && values?.title?.trim() !== ''
            && values?.job_description.trim() !== '' && values?.employer?.trim() !== ''
            && values?.location?.trim() !== '' && values?.start_date) {
            dispatch(toggleNewExperienceForm({
                "showExperienceForm": false,
            }));
            saveExperience({
                "token": token,
                "method": values?.uuid !== '' ? 'PUT' : 'POST',
                "body": Object.assign({}, values, {"linked_resources": [user?.uuid]})
            });
        }
    };

    const validate = values => {
        let errors = {};
        if (!values?.title || values?.title?.trim() === "") {
            errors.title = "You forgot to enter your title for this job."
        }

        if (!values?.employer || values?.employer?.trim() === "") {
            errors.employer = "You forgot enter the employer for this job."
        }

        if (!values?.start_date || values?.start_date === "") {
            errors.start_date = "You forgot enter the start date for this job."
        }

        if (!values?.location || values?.location?.trim() === "") {
            errors.location = "You forgot enter the location for this job."
        }

        if (!values?.job_description || values?.job_description?.trim() === "") {
            errors.job_description = "You forgot enter the job description for this job."
        }
        return errors;
    };

    React.useEffect(() => {
        if(saveResumeExperienceResult?.status === 'fulfilled') {
            reFetchExperiences();
            dispatch(showExperienceForm({
                "activeExperienceID": undefined,
            }));
            tokenizeResource({
                "resource_id": saveResumeExperienceResult?.data?.uuid,
                "field": "job_description",
            });
        }
    });

    return(
        <div className="mt-4 card">
            {
                num_experiences > 0 ? (
                    <div className="card-header">
                        <button
                            type="button"
                            className="btn-close float-end"
                            aria-label="Close"
                            onClick={() => {
                                dispatch(showExperienceForm({
                                    "activeExperienceID": undefined,
                                }));
                                dispatch(toggleNewExperienceForm({
                                    "showExperienceForm": false,
                                }));
                            }}>
                        </button>
                    </div>
                ): null
            }
            <Form
                onSubmit={submit}
                validate={validate}
                initialValues={{
                    uuid: typeof experience?.uuid !== 'undefined' ? experience?.uuid : '',
                    title: typeof experience?.title !== 'undefined' ? experience?.title : '',
                    employer: typeof experience?.employer !== 'undefined' ? experience?.employer : '',
                    location: typeof experience?.location !== 'undefined' ? experience?.location : '',
                    start_date: typeof experience?.start_date !== 'undefined' ? experience?.start_date : '',
                    end_date: typeof experience?.end_date !== 'undefined' ? experience?.end_date : '',
                    current_position: typeof experience?.current_position !== 'undefined' ? experience?.current_position : false,
                    job_description: typeof experience?.job_description !== 'undefined' ? experience?.job_description : '',
                }}
                render={( {handleSubmit, form} ) => {
                    return(
                        <form onSubmit={handleSubmit}>
                            <>
                                { saveResumeExperienceResult?.isLoading || updateUserResult?.isLoading ? (
                                    <div className="p-2">
                                        <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                                        Working...
                                    </div>
                                ): null }
                                <div className="card-body">
                                    <div className="mb-4">
                                        <Field
                                            name="title"
                                            render={({ input, meta }) => (
                                                <div className="mb-4 row">
                                                    <label className="col-sm-2 col-form-label fw-bold">Title</label>
                                                    <div className="col-sm-10">
                                                        <input
                                                            {...input}
                                                            autoComplete="off"
                                                            className="form-control"
                                                            placeholder="Title"
                                                            disabled={saveResumeExperienceResult?.isLoading || updateUserResult?.isLoading}
                                                        />
                                                    </div>
                                                    {!saveResumeExperienceResult?.isLoading ? (
                                                        meta.touched && meta.error && <p className="text-danger mt-2">{meta.error}</p>
                                                    ) : null}
                                                </div>
                                            )}
                                        />
                                    </div>
                                    <div className="mb-4">
                                        <Field
                                            name="employer"
                                            render={({ input, meta }) => (
                                                <div className="mb-4 row">
                                                    <label className="col-sm-2 col-form-label fw-bold">Employer</label>
                                                    <div className="col-sm-10">
                                                        <input
                                                            {...input}
                                                            autoComplete="off"
                                                            className="form-control"
                                                            placeholder="Employer"
                                                            disabled={saveResumeExperienceResult?.isLoading || updateUserResult?.isLoading}
                                                        />
                                                    </div>
                                                    {!saveResumeExperienceResult?.isLoading ? (
                                                        meta.touched && meta.error && <p className="text-danger mt-2">{meta.error}</p>
                                                    ) : null}
                                                </div>
                                            )}
                                        />
                                    </div>
                                    <div className="mb-4">
                                        <Field
                                            name="location"
                                            render={({ input, meta }) => (
                                                <div className="mb-4 row">
                                                    <label className="col-sm-2 col-form-label fw-bold">Location</label>
                                                    <div className="col-sm-10">
                                                        <input
                                                            {...input}
                                                            autoComplete="off"
                                                            className="form-control"
                                                            placeholder="Location"
                                                            disabled={saveResumeExperienceResult?.isLoading || updateUserResult?.isLoading}
                                                        />
                                                    </div>
                                                    {!saveResumeExperienceResult?.isLoading ? (
                                                        meta.touched && meta.error && <p className="text-danger mt-2">{meta.error}</p>
                                                    ) : null}
                                                </div>
                                            )}
                                        />
                                    </div>
                                    <div className="mb-4">
                                        <Field
                                            name="job_description"
                                            render={({ input, meta }) => (
                                                <div className="mb-4 row">
                                                    <label className="col-sm-2 col-form-label fw-bold">Duties</label>
                                                    <div className="col-sm-10">
                                                        <Editor
                                                            tinymceScriptSrc={PUBLIC_URL + '/tinymce/tinymce.min.js'}
                                                            onInit={(evt, editor) => {
                                                                editorRef.current = editor;
                                                            }}
                                                            value={input?.value}
                                                            init={{
                                                                height: 200,
                                                                menubar: false,
                                                                plugins: [
                                                                    'advlist autolink lists link image charmap print preview anchor',
                                                                    'searchreplace visualblocks code fullscreen',
                                                                    'insertdatetime media table paste code help wordcount'
                                                                ],
                                                                toolbar: 'undo redo | formatselect | ' +
                                                                    'bold italic backcolor | alignleft aligncenter ' +
                                                                    'alignright alignjustify | bullist numlist outdent indent | ' +
                                                                    'removeformat ',
                                                                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                                                            }}
                                                            onEditorChange={(content, editor) => {
                                                                input.onChange(content);
                                                            }}
                                                        />
                                                    </div>
                                                    {!updateUserResult?.isLoading ? (
                                                        meta.touched && meta.error && <span className="text-danger">{meta.error}</span>
                                                    ) : null}
                                                </div>
                                            )}
                                        />
                                    </div>
                                    <div className="mb-4">
                                        <Field
                                            name="start_date"
                                            render={({ input, meta }) => (
                                                <div className="mb-4 row">
                                                    <label className="col-sm-2 col-form-label fw-bold">Start Date</label>
                                                    <div className="col-sm-10">
                                                        <DatePicker
                                                            showIcon={true}
                                                            selected={input?.value !== '' ? moment(input?.value).toDate() : new Date()}
                                                            onChange={(date) => {
                                                                input.onChange(date);
                                                            }}
                                                            showMonthYearPicker
                                                            dateFormat="MM/yyyy"
                                                        />
                                                    </div>
                                                    {!saveResumeExperienceResult?.isLoading ? (
                                                        meta.touched && meta.error && <p className="text-danger mt-2">{meta.error}</p>
                                                    ) : null}
                                                </div>
                                            )}
                                        />
                                    </div>
                                    <div className="mb-4">
                                        <Field
                                            name="end_date"
                                            render={({ input, meta }) => (
                                                <div className="mb-4 row">
                                                    <label className="col-sm-2 col-form-label fw-bold">End Date</label>
                                                    <div className="col-sm-10">
                                                        <DatePicker
                                                            showIcon={true}
                                                            selected={input?.value !== '' ? moment(input?.value).toDate() : new Date()}
                                                            onChange={(date) => {
                                                                input.onChange(date);
                                                            }}
                                                            showMonthYearPicker
                                                            dateFormat="MM/yyyy"
                                                        />
                                                    </div>
                                                    {!saveResumeExperienceResult?.isLoading ? (
                                                        meta.touched && meta.error && <p className="text-danger mt-2">{meta.error}</p>
                                                    ) : null}
                                                </div>
                                            )}
                                        />
                                    </div>
                                    <div className="mb-4">
                                        <Field
                                            name="current_position"
                                            render={({ input, meta }) => (
                                                <div className="mb-4 ps-1">
                                                    <div className="form-check">
                                                        <label className="form-check-label" form="flexCheckChecked">
                                                            I'm currently working in this role
                                                        </label>
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            id="flexCheckChecked" defaultChecked={input?.value}
                                                            onChange={(event) => {
                                                                input.onChange(event.target.checked);
                                                            }}
                                                        />
                                                    </div>
                                                    {!saveResumeExperienceResult?.isLoading ? (
                                                        meta.touched && meta.error && <p className="text-danger mt-2">{meta.error}</p>
                                                    ) : null}
                                                </div>
                                            )}
                                        />
                                    </div>
                                </div>
                                <div className="card-footer bg-white">
                                    <button
                                        className="btn btn-secondary mb-2 float-end"
                                        disabled={saveResumeExperienceResult?.isLoading || updateUserResult?.isLoading}
                                        onClick={() => {
                                            submit();
                                        }}
                                    >
                                        {
                                            saveResumeExperienceResult?.isLoading || updateUserResult?.isLoading ? (
                                                <div className="p-2">
                                                    <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                                                    Saving...
                                                </div>
                                            ): "Save"
                                        }
                                    </button>
                                </div>
                            </>
                        </form>
                    )
                }}
            />
        </div>
    );
}

export default ExperienceForm;
