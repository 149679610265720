import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import {
  API_KEY,
  BASE_URL,
  API_URL,
  SCRAPINGBEE_API_KEY,
  OPENAI_API_KEY,
  JOBS_DATA_TYPE_ID,
  RESUMES_DATA_TYPE_ID,
  RESUME_EXPERIENCE_DATA_TYPE_ID,
  RESUME_SKILLS_DATA_TYPE_ID,
  RESUME_EDUCATION_DATA_TYPE_ID,
  RESUME_HEADER_DATA_TYPE_ID, RESUME_SUMMARY_DATA_TYPE_ID
} from '../constants';

export const apiSlice = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({baseUrl: API_URL}),
  endpoints: builder => ({
    signUp: builder.mutation({
      query: data => ({
        url: 'users_3diuek14/',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
        },
        body: {
          email: data.email ? data.email : '',
          first_name: data.first_name ? data.first_name : '',
          last_name: data.last_name ? data.last_name : '',
          password: data.password ? data.password : '',
          tokenize: true
        },
      }),
    }),
    updateUser: builder.mutation({
      query: data => ({
        url: 'users_3diuek14/' + data?.body?.uuid,
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
          Authorization: 'Token ' + data?.token,
        },
        body: JSON.stringify(data?.body),
      }),
    }),
    tokenizeRecourse: builder.mutation({
      query: data => ({
        url: 'tokenize_resource',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
        },
        body: {
          resource_id: data.resource_id ? data.resource_id : '',
          field: data.field ? data.field : '',
        },
      }),
    }),
    isValidUser: builder.mutation({
      query: data => ({
        url: 'users_3diuek14/resource_exists_by_value',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
          'X-API-KEY': API_KEY,
        },
        body: {
          value: data.email ? data.email : '',
          label: 'email',
          tokenize: true,
        },
      }),
    }),
    searchGoogleForJobs: builder.query({
      query: data => ({
        url: 'https://app.scrapingbee.com/api/v1/store/google',
        method: 'GET',
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
        },
        params: {
          'api_key': SCRAPINGBEE_API_KEY,
          'search': data?.search + ' site:https://apply.workable.com',
        },
      }),
    }),
    getSingleJobDetails: builder.query({
      query: data => ({
        url: 'https://app.scrapingbee.com/api/v1/',
        responseHandler: (response) => response.text(),
        method: 'GET',
        headers: {
          'Content-Type': 'text/html; charset=UTF-8',
        },
        params: {
          'api_key': SCRAPINGBEE_API_KEY,
          'url': data?.url
        },
      }),
    }),
    extractKeyDetailsFromJobDescription: builder.mutation({
      query: data => ({
        url: 'https://api.openai.com/v1/chat/completions',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
          Authorization: 'Bearer ' + OPENAI_API_KEY,
        },
        body: {
          model: 'gpt-4',
          messages: [
            { role: 'system', content: "Your job is to extract information from job openings." },
            { role: 'user', content: 'Here is a job opening: ' + data.details + '\n\nExtract and label the hiring company, job description, benefits, and salary from the job opening.'},
          ],
        },
      }),
    }),
    requestClientSecret: builder.mutation({
      query: data => ({
        url: BASE_URL + 'payment_intents',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
          Authorization: 'Token ' + data?.token,
          "X-Api-Key": API_KEY,
        },
        body: JSON.stringify({
          amount: data?.amount,
          payment_method_types: ['card'],
        })
      }),
    }),
    saveJobs: builder.mutation({
      query: data => ({
        url:  'jobs_xjrwy7ao/' + data?.body?.uuid,
        method: data?.method,
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
          Authorization: 'Token ' + data?.token,
          "X-Api-Key": API_KEY,
        },
        body: JSON.stringify(data?.body)
      }),
    }),
    saveResumeHeader: builder.mutation({
      query: data => ({
        url:  'resume_progress_bbbem1xn/' + data?.body?.uuid,
        method: data?.method,
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
          Authorization: 'Token ' + data?.token,
          "X-Api-Key": API_KEY,
        },
        body: JSON.stringify(data?.body)
      }),
    }),
    saveResumeExperience: builder.mutation({
      query: data => ({
        url:  'experince_f19bdl6p/' + data?.body?.uuid,
        method: data?.method,
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
          Authorization: 'Token ' + data?.token,
          "X-Api-Key": API_KEY,
        },
        body: JSON.stringify(data?.body)
      }),
    }),
    saveResumeSkill: builder.mutation({
      query: data => ({
        url:  'skills_yaf9bdpo/' + data?.body?.uuid,
        method: data?.method,
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
          Authorization: 'Token ' + data?.token,
          "X-Api-Key": API_KEY,
        },
        body: JSON.stringify(data?.body)
      }),
    }),
    saveJobCoverLetter: builder.mutation({
      query: data => ({
        url:  'jobs_xjrwy7ao/' + data?.body?.uuid,
        method: data?.method,
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
          Authorization: 'Token ' + data?.token,
          "X-Api-Key": API_KEY,
        },
        body: JSON.stringify(data?.body)
      }),
    }),
    saveResumeEducation: builder.mutation({
      query: data => ({
        url:  'education_mia3z431/' + data?.body?.uuid,
        method: data?.method,
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
          Authorization: 'Token ' + data?.token,
          "X-Api-Key": API_KEY,
        },
        body: JSON.stringify(data?.body)
      }),
    }),
    saveResumeSummary: builder.mutation({
      query: data => ({
        url:  'summary_k7fniyon/' + data?.body?.uuid,
        method: data?.method,
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
          Authorization: 'Token ' + data?.token,
          "X-Api-Key": API_KEY,
        },
        body: JSON.stringify(data?.body)
      }),
    }),
    saveResume: builder.mutation({
      query: data => ({
        url:  'resume_rewritter_tsal0947/' + data?.body?.uuid,
        method: data?.method,
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
          Authorization: 'Token ' + data?.token,
          "X-Api-Key": API_KEY,
        },
        body: JSON.stringify(data?.body)
      }),
    }),
    generate: builder.mutation({
      query: data => ({
        url:  'llms/openAI',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
          Authorization: 'Token ' + data?.token,
          "X-Api-Key": API_KEY,
        },
        body: JSON.stringify(data?.body)
      }),
    }),
    getFileUploadAccess: builder.query({
      query: data => ({
        url: BASE_URL + 'auth/request_upload_access',
        method: 'GET',
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
          Authorization: 'Token ' + data?.token,
          "X-Api-Key": API_KEY,
        },
      }),
    }),
    getJobs: builder.query({
      query: data => ({
        url:
            'users_3diuek14/' +
            data?.user_id +
            '/links?start=1&limit=100&data_type_id=' +
            JOBS_DATA_TYPE_ID,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
          Authorization: 'Token ' + data?.token,
          "X-Api-Key": API_KEY,
        },
      }),
    }),
    getResume: builder.query({
      query: data => ({
        url:
            'users_3diuek14/' +
            data?.user_id +
            '/links?start=1&limit=1&data_type_id=' +
            RESUMES_DATA_TYPE_ID,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
          Authorization: 'Token ' + data?.token,
          "X-Api-Key": API_KEY,
        },
      }),
    }),
    getResumeHeader: builder.query({
      query: data => ({
        url:
            'users_3diuek14/' +
            data?.user_id +
            '/links?start=1&limit=1&data_type_id=' +
            RESUME_HEADER_DATA_TYPE_ID,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
          Authorization: 'Token ' + data?.token,
          "X-Api-Key": API_KEY,
        },
      }),
    }),
    getResumeSummary: builder.query({
      query: data => ({
        url:
            'users_3diuek14/' +
            data?.user_id +
            '/links?start=1&limit=1&data_type_id=' +
            RESUME_SUMMARY_DATA_TYPE_ID,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
          Authorization: 'Token ' + data?.token,
          "X-Api-Key": API_KEY,
        },
      }),
    }),
    getResumeExperience: builder.query({
      query: data => ({
        url:
            'users_3diuek14/' +
            data?.user_id +
            '/links?start=1&limit=50&data_type_id=' +
            RESUME_EXPERIENCE_DATA_TYPE_ID,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
          Authorization: 'Token ' + data?.token,
          "X-Api-Key": API_KEY,
        },
      }),
    }),
    getResumeEducation: builder.query({
      query: data => ({
        url:
            'users_3diuek14/' +
            data?.user_id +
            '/links?start=1&limit=10&data_type_id=' +
            RESUME_EDUCATION_DATA_TYPE_ID,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
          Authorization: 'Token ' + data?.token,
          "X-Api-Key": API_KEY,
        },
      }),
    }),
    getResumeSkills: builder.query({
      query: data => ({
        url:
            'users_3diuek14/' +
            data?.user_id +
            '/links?start=1&limit=1&data_type_id=' +
            RESUME_SKILLS_DATA_TYPE_ID,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
          Authorization: 'Token ' + data?.token,
          "X-Api-Key": API_KEY,
        },
      }),
    }),
    getPaymentIntent: builder.query({
      query: data => ({
        url: BASE_URL + 'payment_intents?payment_intent_id=' + data?.payment_intent_id,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
          Authorization: 'Token ' + data?.token,
          "X-Api-Key": API_KEY,
        },
      }),
    }),
    getJobByID: builder.query({
      query: data => ({
        url: 'jobs_xjrwy7ao/' + data?.uuid,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
          Authorization: 'Token ' + data?.token,
          "X-Api-Key": API_KEY,
        },
      }),
    }),
    getSimilarSentencesFromResource: builder.query({
      query: data => ({
        url: 'sentences/' + data?.sentence_id + '/similarities/' + data?.resource_id,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
          Authorization: 'Token ' + data?.token,
          "X-Api-Key": API_KEY,
        },
      }),
    }),
    getTokenizedResourceByID: builder.query({
      query: data => ({
        url: `tokenize_resource/${data?.uuid || ''}${typeof data?.compare_resource_id !== 'undefined' ? `?compare_resource_id=${data.compare_resource_id}` : ''}`,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json; charset=UTF-8',
          Authorization: 'Token ' + data?.token,
          "X-Api-Key": API_KEY,
        },
      }),
    }),
  }),
});

export const {
    useSignUpMutation,
    useUpdateUserMutation,
    useIsValidUserMutation,
    useSaveJobsMutation,
    useSaveResumeSummaryMutation,
    useSaveJobCoverLetterMutation,
    useRequestClientSecretMutation,
    useGenerateMutation,
    useSaveResumeHeaderMutation,
    useSaveResumeExperienceMutation,
    useSaveResumeSkillMutation,
    useSaveResumeEducationMutation,
    useGetResumeHeaderQuery,
    useGetResumeExperienceQuery,
    useGetResumeEducationQuery,
    useGetResumeSkillsQuery,
    useGetResumeSummaryQuery,
    useGetJobsQuery,
    useGetPaymentIntentQuery,
    useGetTokenizedResourceByIDQuery,
    useGetJobByIDQuery,
    useSearchGoogleForJobsQuery,
    useGetSingleJobDetailsQuery,
    useGetFileUploadAccessQuery,
    useExtractKeyDetailsFromJobDescriptionMutation,
    useTokenizeRecourseMutation,
} = apiSlice;
