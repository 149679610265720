import * as React from 'react';
import {Outlet} from "react-router-dom";
import Footer from "../Footer";
import Cart from "../Home/Cart";

function Dashboard() {
    return (
        <>
            <Outlet />
            <Cart />
            <Footer />
        </>
    );
}

export default Dashboard;
