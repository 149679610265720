import * as React from 'react';
import {useNavigate} from "react-router-dom";

const CartButtons = ({cart}) => {
    const navigate = useNavigate();
    return(
        <div className="card border-0 bg-light">
            <div className="card-body">
                <p>{Object.keys(cart)?.length} jobs in cart</p>
                <button
                    onClick={() => {navigate('/checkout')}}
                    className="btn btn-primary btn-sm">
                    Checkout
                </button>
            </div>
        </div>
    )
};

export default CartButtons;
