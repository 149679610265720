import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from './store';
import Home from "./components/Home";
import reportWebVitals from './reportWebVitals';
import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom";
import Jobs from "./components/Home/Jobs";
import Resume from "./components/Home/Resume";
import JobForm from "./components/Home/Job/JobForm";

const router = createBrowserRouter([
    {
        path: "/",
        element: <Home />,
        children: [
            {
                path: "/",
                element: <JobForm />
            },
            {
                path: "/resume",
                element: <Resume />
            },
            {
                path: "/jobs",
                element: <Jobs />
            },
            {
                path: "/jobs/:uuid",
                element: <Jobs />
            },
            {
                path: "/jobs/:uuid/edit",
                element: <JobForm />
            },
        ]
    }
]);
const container = document.getElementById('root');
const root = createRoot(container);

root.render(
    <React.StrictMode>
        <Provider store={store}>
            <RouterProvider router={router} />
        </Provider>
    </React.StrictMode>
);
reportWebVitals();
