export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const API_URL = process.env.REACT_APP_API_URL;
export const OAUTH_URL = process.env.REACT_APP_OAUTH_URL;
export const API_KEY = process.env.REACT_APP_API_KEY;
export const SECRETE_KEY = process.env.REACT_APP_SECRETE_KEY;
export const SCRAPINGBEE_API_KEY = process.env.REACT_APP_SCRAPINGBEE_API_KEY;
export const OPENAI_API_KEY = process.env.REACT_APP_OPENAI_API_KEY;
export const STRIPE_KEY = process.env.REACT_APP_STRIPE_KEY;
export const JOBS_DATA_TYPE_ID = process.env.REACT_APP_JOBS_DATA_TYPE_ID;
export const RESUMES_DATA_TYPE_ID = process.env.REACT_APP_RESUMES_DATA_TYPE_ID;
export const RESUME_EDUCATION_DATA_TYPE_ID = process.env.REACT_APP_RESUME_EDUCATION_DATA_TYPE_ID;
export const RESUME_SKILLS_DATA_TYPE_ID = process.env.REACT_APP_RESUME_SKILLS_DATA_TYPE_ID;
export const RESUME_EXPERIENCE_DATA_TYPE_ID = process.env.REACT_APP_RESUME_EXPERIENCE_DATA_TYPE_ID;
export const RESUME_HEADER_DATA_TYPE_ID = process.env.REACT_APP_RESUME_HEADER_DATA_TYPE_ID;
export const RESUME_SUMMARY_DATA_TYPE_ID = process.env.REACT_APP_RESUME_SUMMARY_DATA_TYPE_ID;
export const PUBLIC_URL = process.env.REACT_APP_PUBLIC_URL;
export const generatePassword = () => {
    const chars = "0123456789abcdefghijklmnopqrstuvwxyz!@#$%^&*()ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    const passwordLength = 12;
    let password = "";
    for (let i = 0; i <= passwordLength; i++) {
        const randomNumber = Math.floor(Math.random() * chars.length);
        password += chars.substring(randomNumber, randomNumber +1);
    }
    return password;
}

export function validateEmail(email) {
    let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

export const degrees = [
    {"level": "Secondary Education", "degree": "High School Diploma"},
    {"level": "Secondary Education", "degree": "GED (General Educational Development)"},
    {"level": "Postsecondary Undergraduate", "degree": "Associate of Arts (AA)"},
    {"level": "Postsecondary Undergraduate", "degree": "Associate of Science (AS)"},
    {"level": "Postsecondary Undergraduate", "degree": "Associate of Applied Science (AAS)"},
    {"level": "Postsecondary Undergraduate", "degree": "Bachelor of Arts (BA)"},
    {"level": "Postsecondary Undergraduate", "degree": "Bachelor of Science (BS)"},
    {"level": "Postsecondary Undergraduate", "degree": "Bachelor of Fine Arts (BFA)"},
    {"level": "Postsecondary Undergraduate", "degree": "Bachelor of Business Administration (BBA)"},
    {"level": "Postsecondary Undergraduate", "degree": "Bachelor of Engineering (BE, BEng)"},
    {"level": "Postgraduate", "degree": "Master of Arts (MA)"},
    {"level": "Postgraduate", "degree": "Master of Science (MS)"},
    {"level": "Postgraduate", "degree": "Master of Business Administration (MBA)"},
    {"level": "Postgraduate", "degree": "Master of Fine Arts (MFA)"},
    {"level": "Postgraduate", "degree": "Master of Engineering (ME, MEng)"},
    {"level": "Postgraduate", "degree": "Master of Education (MEd)"},
    {"level": "Advanced Postgraduate", "degree": "Doctor of Philosophy (PhD)"},
    {"level": "Advanced Postgraduate", "degree": "Doctor of Medicine (MD)"},
    {"level": "Advanced Postgraduate", "degree": "Doctor of Dental Surgery (DDS)"},
    {"level": "Advanced Postgraduate", "degree": "Juris Doctor (JD)"},
    {"level": "Advanced Postgraduate", "degree": "Doctor of Education (EdD)"},
    {"level": "Advanced Postgraduate", "degree": "Doctor of Engineering (DEng)"}
];

export const areas_of_study = [
    {"field": "Arts and Humanities"},
    {"field": "Anthropology"},
    {"field": "Archaeology"},
    {"field": "Architecture"},
    {"field": "Art History"},
    {"field": "Classics"},
    {"field": "Communication Studies"},
    {"field": "Design"},
    {"field": "Film, Television, and Media"},
    {"field": "History"},
    {"field": "Languages and Literature"},
    {"field": "Music"},
    {"field": "Philosophy"},
    {"field": "Religious Studies"},
    {"field": "Theater and Drama"},
    {"field": "Sciences"},
    {"field": "Astronomy"},
    {"field": "Biology"},
    {"field": "Chemistry"},
    {"field": "Earth Sciences"},
    {"field": "Environmental Science"},
    {"field": "Physics"},
    {"field": "Psychology"},
    {"field": "Zoology"},
    {"field": "Social Sciences"},
    {"field": "Economics"},
    {"field": "Education"},
    {"field": "Geography"},
    {"field": "Political Science"},
    {"field": "Sociology"},
    {"field": "Business"},
    {"field": "Accounting"},
    {"field": "Finance"},
    {"field": "Marketing"},
    {"field": "Management"},
    {"field": "Human Resources"},
    {"field": "Engineering and Technology"},
    {"field": "Aerospace Engineering"},
    {"field": "Biomedical Engineering"},
    {"field": "Chemical Engineering"},
    {"field": "Civil Engineering"},
    {"field": "Computer Science"},
    {"field": "Electrical Engineering"},
    {"field": "Mechanical Engineering"},
    {"field": "Software Engineering"},
    {"field": "Healthcare"},
    {"field": "Medicine"},
    {"field": "Nursing"},
    {"field": "Pharmacy"},
    {"field": "Public Health"},
    {"field": "Dentistry"},
    {"field": "Law"}
];

export const merge = (a, b, predicate = (a, b) => a === b) => {
    const c = [...a]; // copy to avoid side effects
    // add all items from B to copy C if they're not already present
    b.forEach((bItem) => (c.some((cItem) => predicate(bItem, cItem)) ? null : c.push(bItem)))
    return c;
}
